import React, { useState, useEffect } from "react";
import { getUserStatus,getAssignedUsers } from "../../Data/dsaapis";
import moment from "moment";

const DsaHome = () => {
    const [userInfo, setuserInfo] = useState("");
    const [assignedCount, setAssignedCount] = useState("");

    const fetch = async() => {
        const response =await getUserStatus();
        setuserInfo(response.data)

        const assignedUsers =await getAssignedUsers();
        setAssignedCount(assignedUsers.data.length)
      };
    
      useEffect(() => {
        fetch();
      }, []);
  return (
    <div className="w-full">
      <div className="mx-5 mt-8 mb-3">
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg ">
          <div
            className="is-scrollbar-hidden min-w-full overflow-x-auto"
            style={{ width: "" }}
          >
              {userInfo != "" && (
              <div className="mx-5 flex">
                <div className="card" style={{ width: "" }}>
                  <div className="flex rounded-md bg-slate-200 justify-center">
                    <div className="text-lg">User Information</div>
                  </div>

                  <div className="grid grid-cols-4 gap-8 ml-4">
                    

                    <div >
                      <h6 className="text-sm mt-2 font-semibold">Name:</h6>
                      <h5 className="text-lg font-semibold">
                        {userInfo.name}
                      </h5>
                      <h6 className="text-sm  mt-2 font-semibold">Email:</h6>
                      <h5 className="text-lg font-semibold">
                        {userInfo.email}
                      </h5>
                      <h6 className="text-sm  mt-2 font-semibold">Number:</h6>
                      <h5 className="text-lg font-semibold">
                        {userInfo.number}
                      </h5>
                      <h6 className="text-sm  mt-2  font-semibold">Account created:</h6>
                      <h5 className="text-lg mb-2 font-semibold">
                      {moment(userInfo.date).format("D-M-YYYY hh:mm:ss")}
                      </h5>


                      <h6 className="text-sm  mt-2  font-semibold">Total Assigned Users:</h6>
                      <h5 className="text-lg mb-2 font-semibold">
                      {assignedCount}
                      </h5>
                      
                    </div>


                   
                    
                  </div>
                </div>

               
                </div>
              
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DsaHome;
