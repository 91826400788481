import React from 'react';
import DocUpload from '../../Components/DocUpload';
const Documents = () => {
    return (
        <div>
            <DocUpload/>
        </div>
    );
};

export default Documents;