import axios from "axios";

const apiUrl = process.env.REACT_APP_BACKEND_URL;

export const getAllUsers = async () => {
  //api call
  const url = `${apiUrl}/api/all_users`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "auth-token": localStorage.getItem("token"),
    },
  });
  const json = await response.json();

  return json;
};

export const fetchUserInfo = async (id) => {
  //api call
  const url = `${apiUrl}/api/fetchsingle/${id}`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "auth-token": localStorage.getItem("token"),
    },
  });
  const json = await response.json();

  return json;
};

export const updateLoanStatus = async (id, status) => {
  const url = `${apiUrl}/api/file/loanStatusUpdate/${id}`;
  const response = await fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "auth-token": localStorage.getItem("token"),
    },
    body: JSON.stringify({
      status: status,
    }),
  });
  const json = await response.json();

  return json;
};

export const getUserFiles = async (userId) => {
  const url = `${apiUrl}/api/file/getSingleFilesAdmin`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": localStorage.getItem("token"),
    },
    body: JSON.stringify({
      user_id: userId,
    }),
  });
  const json = await response.json();

  return json;
};

export const updateFileStatus = async (id, status) => {
  const url = `${apiUrl}/api/file/updateStatus/${id}`;
  const response = await fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "auth-token": localStorage.getItem("token"),
    },
    body: JSON.stringify({
      status: status,
    }),
  });
  const json = await response.json();

  return json;
};

export const getUserNotifications = async (userId) => {
  const url = `${apiUrl}/api/loan/get_notification`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": localStorage.getItem("token"),
    },
    body: JSON.stringify({
      user_id: userId,
    }),
  });
  const json = await response.json();

  return json;
};

export const sendNotification = async (userId, notification) => {
  const url = `${apiUrl}/api/loan/send_notification`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": localStorage.getItem("token"),
    },
    body: JSON.stringify({
      user_id: userId,
      message: notification,
    }),
  });
  const json = await response.json();

  return json;
};

export const updateUserDetail = async (data, userId) => {
  //api call
  const url = `${apiUrl}/api/updateuserdetails`;
  const response = await fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "auth-token": localStorage.getItem("token"),
    },

    body: JSON.stringify({
      gender: data.gender,
      dob: data.dob,
      occupation: data.occupation,
      district: data.district,
      fatheroccupation: data.father_occupation,
      motheroccupation: data.mother_occupation,
      familyincome: data.family_annual_income,
      parentnumber: data.parent_contact_number,
      fullname: data.name,
      fathersname: data.father_name,
      mothersname: data.mother_name,
      email: data.email,
      number: data.mobile,
      course: data.course,
      joiningyear: data.joining_year,
      metric: data.tenth,
      highersecondary: data.twelth,
      passedcourse: data.graduated_course,
      passinggrade: data.graduated_grade,
      address: data.address,
      address2: data.street_name,
      city: data.city,
      state: data.state,
      zipcode: data.zip_code,
      userid: userId,
    }),
  });
  const json = await response.json();

  return json;
};

export const getTransactions = async () => {
  const url = `${apiUrl}/api/get_transactions`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "auth-token": localStorage.getItem("token"),
    },
  });
  const json = await response.json();

  return json;
};

export const dashboardStats = async () => {
  const url = `${apiUrl}/api/admin_dashboard_data`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "auth-token": localStorage.getItem("token"),
    },
  });
  const json = await response.json();

  return json;
};

export const getDsas = async () => {
  const url = `${apiUrl}/api/list_dsa`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "auth-token": localStorage.getItem("token"),
    },
  });
  const json = await response.json();

  return json;
};

export const getAllDsas = async () => {
  const url = `${apiUrl}/api/list_all_dsa`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "auth-token": localStorage.getItem("token"),
    },
  });
  const json = await response.json();

  return json;
};

//user from user collection
export const getUsers = async () => {
  const url = `${apiUrl}/api/users`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "auth-token": localStorage.getItem("token"),
    },
  });
  const json = await response.json();

  return json;
};

export const createDsa = async (data) => {
  const url = `${apiUrl}/api/create_dsa`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": localStorage.getItem("token"),
    },
    body: JSON.stringify({
      name: data.name,
      email: data.email,
      number: data.number,
      password: data.password,
    }),
  });
  const json = await response.json();

  return json;
};

export const createUser = async (data) => {
  const url = `${apiUrl}/api/auth/newuser`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": localStorage.getItem("token"),
    },
    body: JSON.stringify({
      name: data.name,
      email: data.email,
      number: data.number,
      password: data.password,
    }),
  });
  const json = await response.json();

  return json;
};

export const assignDsa = async (userId, dsaId) => {
  const url = `${apiUrl}/api/assign_dsa`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": localStorage.getItem("token"),
    },
    body: JSON.stringify({
      user: userId,
      dsa: dsaId,
    }),
  });
  const json = await response.json();

  return json;
};

export const changeUserStatus = async (status, userId) => {
  const url = `${apiUrl}/api/change_user_status`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": localStorage.getItem("token"),
    },
    body: JSON.stringify({
      user_id: userId,
      status: status,
    }),
  });
  const json = await response.json();

  return json;
};

export const deleteDsa = async (userId) => {
  const url = `${apiUrl}/api/delete_dsa`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": localStorage.getItem("token"),
    },
    body: JSON.stringify({
      userid: userId,
    }),
  });
  const json = await response.json();

  return json;
};

export const deleteUser = async (userId) => {
  const url = `${apiUrl}/api/delete_user`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": localStorage.getItem("token"),
    },
    body: JSON.stringify({
      userid: userId,
    }),
  });
  const json = await response.json();

  return json;
};

//get Image
export const fetchImageAdmin = async (imageurl) => {
  //api call
  const url = `${apiUrl}/api/get_image`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": localStorage.getItem("token"),
    },
    body: JSON.stringify({
      url: imageurl,
    }),
  });

  const imageBlob = await response.blob();
  const imageObjectURL = URL.createObjectURL(imageBlob);

  return imageObjectURL;
};
