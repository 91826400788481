import React, { useState, useEffect } from "react";
import {
  ToastErrorNotifications,
  ToastSuccessNotifications,
} from "../../Notifications/ToastNotifications";
import { getTransactions, dashboardStats } from "../../Data/adminapis";
import {
  faUser,
  faLandmark,
  faMoneyBill
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AdminHome = () => {
  const [stats, setstats] = useState("");
  const [transactions, settransactions] = useState("");

  const fetch = async () => {
    const statsResponse = await dashboardStats();
    setstats(statsResponse.data);
    const transactionResponse = await getTransactions();
    settransactions(transactionResponse.data);
    console.log(statsResponse,transactionResponse)
  };

  useEffect(()=>{
    fetch()
    console.log(stats)
  },[])
  return (
   
      <div className="w-full">
        <div className="mx-5 mt-8 mb-3">
          <div className=" ">
          <div
            className=""
            style={{ width: "" }}
          >
            <div className="grid grid-cols-2 gap-3 sm:grid-cols-3 sm:gap-5 lg:grid-cols-6">
              <div className="rounded-lg bg-slate-150 p-4 dark:bg-navy-700">
                <div className="flex justify-between space-x-1">
                  <p className="text-xl font-semibold text-slate-700 dark:text-navy-100">
                  {stats.total_users}
                  </p>
                  <FontAwesomeIcon
                              className="mx-2   px-2 py-1 text-lg text-black-700"
                              icon={faUser}
                            />
                </div>
                <p className="mt-1 text-xs+">Total Users</p>
              </div>



              <div className="rounded-lg bg-slate-150 p-4 dark:bg-navy-700">
                <div className="flex justify-between">
                  <p className="text-xl font-semibold text-slate-700 dark:text-navy-100">
                  {stats.total_paid_users}
                  </p>
                  <FontAwesomeIcon
                              className="mx-2   px-2 py-1 text-lg text-green-600"
                              icon={faUser}
                            />
                </div>
                <p className="mt-1 text-xs+">Total Paid Users</p>
              </div>




              <div className="rounded-lg bg-slate-150 p-4 dark:bg-navy-700">
                <div className="flex justify-between">
                  <p className="text-xl font-semibold text-slate-700 dark:text-navy-100">
                  {stats.total_unpaid_users}
                  </p>
                  <FontAwesomeIcon
                              className="mx-2   px-2 py-1 text-lg text-yellow-600"
                              icon={faUser}
                            />
                </div>
                <p className="mt-1 text-xs+">Total unpaid users</p>
              </div>



              <div className="rounded-lg bg-slate-150 p-4 dark:bg-navy-700">
                <div className="flex justify-between">
                  <p className="text-xl font-semibold text-slate-700 dark:text-navy-100">
                  {stats.total_loans}
                  </p>
                  <FontAwesomeIcon
                              className="mx-2   px-2 py-1 text-lg text-blue-600"
                              icon={faLandmark}
                            />
                </div>
                <p className="mt-1 text-xs+">Total Loans</p>
              </div>

              <div className="rounded-lg bg-slate-150 p-4 dark:bg-navy-700">
                <div className="flex justify-between">
                  <p className="text-xl font-semibold text-slate-700 dark:text-navy-100">
                  {stats.total_transactions}
                  </p>
                  <FontAwesomeIcon
                              className="mx-2   px-2 py-1 text-lg text-orange-600"
                              icon={faMoneyBill}
                            />
                </div>
                <p className="mt-1 text-xs+">Total Transactions</p>
              </div>


              <div className="rounded-lg bg-slate-150 p-4 dark:bg-navy-700">
                <div className="flex justify-between">
                  <p className="text-xl font-semibold text-slate-700 dark:text-navy-100">
                  {stats.total_amount_paid}
                  </p>
                  <FontAwesomeIcon
                              className="mx-2   px-2 py-1 text-lg text-green-600"
                              icon={faMoneyBill}
                            />
                </div>
                <p className="mt-1 text-xs+">Total Amount Paid</p>
              </div>


      
            </div>

            <table className="is-hoverable w-full text-left mt-8">
              <thead>
                <tr>
                  <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                    #
                  </th>
                  <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                    sid
                  </th>
                  <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                    Amount
                  </th>
                  <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                    Status
                  </th>
                  <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                    Transaction Id
                  </th>
                  <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                    Bank ref id
                  </th>
                  <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                    Name
                  </th>
                  <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                   Payment Mode
                  </th>
                  <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                  Email
                  </th>
                  <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                  Mobile
                  </th>
                  <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                  Remark
                  </th>
                  <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                  Acquirer Name
                  </th>
                  <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                  Transaction Date
                  </th>
                </tr>
              </thead>
              <tbody>
                {transactions.length > 0 &&
                  transactions.map((item, index) => (
                    <tr
                      key={item._id}
                      className="border border-transparent border-b-slate-200 dark:border-b-navy-500"
                    >
                      <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                        {index + 1}
                      </td>

                      <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                        {item.sid}
                      </td>
                      <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                        {item.amount}
                      </td>
                      <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                        {item.status}
                      </td>


                      <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                        {item.transaction_id}
                      </td>
                      <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                        {item.bank_ref_id}
                      </td>
                      <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                        {item.name}
                      </td>
                      <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                        {item.payment_mode}
                      </td>
                      <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                        {item.email}
                      </td>
                      <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                        {item.mobile_number}
                      </td>
                      <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                        {item.remark}
                      </td>
                      <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                        {item.acquirer_name}
                      </td>
                      <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                        {item.transaction_date}
                      </td>
                     
                      

                     
                    </tr>
                  ))}
              </tbody>
            </table>


          
           
          </div>
        </div>
      </div>
    </div>
   
  );
};

export default AdminHome;
