import React, { useState, useEffect } from "react";
import {
  ToastErrorNotifications,
  ToastSuccessNotifications,
} from "../../Notifications/ToastNotifications";
import { getAssignedUsers } from "../../Data/dsaapis";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  faEye,
  faCheck,
  faBan,
  faArrowsRotate,
} from "@fortawesome/free-solid-svg-icons";

const DsaUser = () => {
  const [users, setUsers] = useState("");

  const fetch = async() => {
    const response =await getAssignedUsers();
    setUsers(response.data)
  };

  useEffect(() => {
    fetch();
  }, []);
  return  <div className="w-full">
  <div className="mx-5 mt-8 mb-3">
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg ">
      <div
        className="is-scrollbar-hidden min-w-full overflow-x-auto"
        style={{ width: "" }}
      >
        

        <table className="is-hoverable w-full text-left">
          <thead>
            <tr>
              <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                #
              </th>
              <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                Name
              </th>
             
              <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                Loan Status
              </th>
              <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {users.length > 0 &&
              users.map((item, index) => (
                <tr
                  key={item._id}
                  className="border border-transparent border-b-slate-200 dark:border-b-navy-500"
                >
                  <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                    
                    {index + 1}
                  </td>

                  <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                    {item.fullname}
                  </td>
                
                  {item.status == "Approved" ? (
                    <td className=" whitespace-nowrap px-4 py-3 sm:px-5">
                      <span className="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded ">
                        {item.status}
                      </span>
                    </td>
                  ) : item.status == "Declined" ? (
                    <td className=" whitespace-nowrap px-4 py-3 sm:px-5">
                      <span className="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded ">
                        {item.status}
                      </span>
                    </td>
                  ) : (
                    <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                      <span className="bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded ">
                        {item.status}
                      </span>
                    </td>
                  )}

                  <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                    <Link to={`/dsa_user/${item.user._id}`}>
                      <button>
                        <FontAwesomeIcon
                          className="mx-2 rounded-md border border-red-400 border-dotted px-2 py-1 text-lg text-green-600"
                          icon={faEye}
                        />
                      </button>
                    </Link>

                  

                   

                   
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>;
};

export default DsaUser;
