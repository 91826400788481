import React from 'react';
import { Helmet } from "react-helmet";
import Navbar from './Component/Navbar';
const AboutUs = () => {
    return (
        <>
  {/* Mirrored from templates.envytheme.com/finix/default/about.html by HTTrack Website Copier/3.x [XR&CO'2014], Sun, 26 Feb 2023 16:40:34 GMT */}
  {/* Required meta tags */}
  <meta charSet="utf-8" />
  <meta
    name="viewport"
    content="width=device-width, initial-scale=1, shrink-to-fit=no"
  />
  <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        {/* Bootstrap CSS */} 
        <link rel="stylesheet" href="assets/css/bootstrap.min.css" />
        {/* Animate CSS */} 
        <link rel="stylesheet" href="assets/css/animate.min.css" />
        {/* Meanmenu CSS */}
        <link rel="stylesheet" href="assets/css/meanmenu.css" />
        {/* Boxicons CSS */}
        <link rel="stylesheet" href="assets/css/boxicons.min.css" />
        {/* Flaticon CSS */}
        <link rel="stylesheet" href="assets/css/flaticon.css" />
        {/* Carousel CSS */}
        <link rel="stylesheet" href="assets/css/owl.carousel.min.css" />
        {/* Carousel Default CSS */}
        <link rel="stylesheet" href="assets/css/owl.theme.default.min.css" />
        {/* Magnific Popup CSS */}
        <link rel="stylesheet" href="assets/css/magnific-popup.min.css" />
        {/* Nice Select CSS */}
        <link rel="stylesheet" href="assets/css/nice-select.min.css" />
        {/* Odometer CSS */}
        <link rel="stylesheet" href="assets/css/odometer.min.css" />
        {/* Style CSS */}
        <link rel="stylesheet" href="assets/css/style.css" />
        {/* Dark CSS */}
        <link rel="stylesheet" href="assets/css/dark.css" />
        {/* Responsive CSS */}
        <link rel="stylesheet" href="assets/css/responsive.css" />
        <title>Finix - Loans &amp; Funding HTML Template</title>
        <link rel="icon" type="image/png" href="assets/img/favicon.png" />
        </Helmet>
        <Navbar />
  
  {/* End Navbar Area */}
  {/* Start Page Title Area */}
  <div className="page-title-area item-bg-1">
    <div className="d-table">
      <div className="d-table-cell">
        <div className="container">
          <div className="page-title-content">
            <h2>About Us</h2>
            <ul>
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>About</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* End Page Title Area */}
  {/* Start About Area Area */}
  <section className="about-area ptb-100">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-7">
          <div className="about-image">
            <img src="assets/img/about/image1.jpg" alt="image" />
            <img src="assets/img/about/image2.jpg" alt="image" />
            <div className="experience">
              <h4>25</h4>
              <p>We have more than years of experience</p>
            </div>
          </div>
        </div>
        <div className="col-lg-5">
          <div className="about-content">
            <span>About company</span>
            <h3>
              We have been working very efficiently with loan and funding for 25
              years.
            </h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
              ipsum suspendisse ultrices gravida.
            </p>
            <strong>In business, we focus on 3 things.</strong>
            <ul className="about-list">
              <li>
                <i className="flaticon-check" />
                Useful info
              </li>
              <li>
                <i className="flaticon-check" />
                Reliability
              </li>
              <li>
                <i className="flaticon-check" />
                Innovation
              </li>
            </ul>
            <div className="about-btn">
              <a href="apply-now.html" className="default-btn">
                View more
                <span />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* End About Area Area */}
  {/* Start Choose Area */}
  <section className="choose-area pt-100 pb-70">
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <div className="choose-title">
            <span>Transparent process</span>
            <h2>Why people choose us</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
          </div>
          <div className="choose-image">
            <img src="assets/img/choose.png" alt="image" />
            <a
              href="https://www.youtube.com/watch?v=qaHWDmFtBl0"
              className="video-btn popup-youtube"
            >
              <i className="flaticon-play-button" />
            </a>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="process-item bg-top1">
                <div className="icon">
                  <i className="flaticon-guarantee" />
                </div>
                <h3>Guarantee</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="process-item bg-top2">
                <div className="icon">
                  <i className="flaticon-speed" />
                </div>
                <h3>Speed</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="process-item bg-top3">
                <div className="icon">
                  <i className="flaticon-reliability" />
                </div>
                <h3>Reliability</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="process-item bg-top4">
                <div className="icon">
                  <i className="flaticon-user-experience" />
                </div>
                <h3>Experience</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* End Choose Area */}
  {/* Start Clients Area */}
  <section className="clients-area pt-100 pb-70">
    <div className="container">
      <div className="section-title">
        <span>Clients words</span>
        <h2>What our clients say</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
        </p>
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-6">
          <div className="clients-item">
            <div className="info">
              <img src="assets/img/clients/image1.png" alt="image" />
              <h4>Markus Twain</h4>
              <span>Client</span>
            </div>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
              ipsum suspendisse ultrices gravida.
            </p>
            <div className="icon">
              <i className="flaticon-right-quote" />
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div className="clients-item">
            <div className="info">
              <img src="assets/img/clients/image2.png" alt="image" />
              <h4>Jessica Molony</h4>
              <span>CEO</span>
            </div>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
              ipsum suspendisse ultrices gravida.
            </p>
            <div className="icon">
              <i className="flaticon-right-quote" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* End Clients Area */}
  {/* Start Partner Area */}
  <div className="partner-area ptb-100">
    <div className="container">
      <div className="partner-slider owl-carousel owl-theme">
        <div className="partner-item">
          <img src="assets/img/partner/1.png" alt="image" />
        </div>
        <div className="partner-item">
          <img src="assets/img/partner/2.png" alt="image" />
        </div>
        <div className="partner-item">
          <img src="assets/img/partner/3.png" alt="image" />
        </div>
        <div className="partner-item">
          <img src="assets/img/partner/4.png" alt="image" />
        </div>
        <div className="partner-item">
          <img src="assets/img/partner/5.png" alt="image" />
        </div>
      </div>
    </div>
  </div>
  {/* End Partner Area */}
  {/* Start Team Area */}
  <section className="team-area bg-color pt-100 pb-70">
    <div className="container">
      <div className="section-title">
        <span>Expert People</span>
        <h2>Global leadership team</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
      </div>
      <div className="row">
        <div className="col-lg-3 col-sm-6">
          <div className="team-item">
            <div className="image">
              <img src="assets/img/team/image1.jpg" alt="image" />
              <ul className="social">
                <li>
                  <a href="#" target="_blank">
                    <i className="bx bxl-twitter" />
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <i className="bx bxl-linkedin" />
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <i className="bx bxl-facebook" />
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <i className="bx bxl-instagram" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="content">
              <h3>Jerome Le Luel</h3>
              <span>Managing Director</span>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div className="team-item">
            <div className="image">
              <img src="assets/img/team/image2.jpg" alt="image" />
              <ul className="social">
                <li>
                  <a href="#" target="_blank">
                    <i className="bx bxl-twitter" />
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <i className="bx bxl-linkedin" />
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <i className="bx bxl-facebook" />
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <i className="bx bxl-instagram" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="content">
              <h3>Andrew Learoyd</h3>
              <span>Chief Executive Officer</span>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div className="team-item">
            <div className="image">
              <img src="assets/img/team/image3.jpg" alt="image" />
              <ul className="social">
                <li>
                  <a href="#" target="_blank">
                    <i className="bx bxl-twitter" />
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <i className="bx bxl-linkedin" />
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <i className="bx bxl-facebook" />
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <i className="bx bxl-instagram" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="content">
              <h3>Harry Nelis</h3>
              <span>Technology Officer</span>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div className="team-item">
            <div className="image">
              <img src="assets/img/team/image4.jpg" alt="image" />
              <ul className="social">
                <li>
                  <a href="#" target="_blank">
                    <i className="bx bxl-twitter" />
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <i className="bx bxl-linkedin" />
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <i className="bx bxl-facebook" />
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <i className="bx bxl-instagram" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="content">
              <h3>Lucy Vernall</h3>
              <span>Chief Financial Officer</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* End Team Area */}
  {/* Start Solution Area */}
  <section className="solution-area bg-top">
    <div className="container">
      <div className="solution-item">
        <div className="row align-items-center">
          <div className="col-lg-7">
            <div className="content">
              <h3>
                We are here to help your business Call to discuss your Solution
              </h3>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="solution-info">
              <i className="flaticon-telephone" />
              <h3>
                <a href="tel:15143125678">+1 (514) 312-5678</a>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* End Solution Area */}
  {/* Start Footer Area */}
  <section className="footer-area bg-top pt-100 pb-70">
    <div className="container">
      <div className="row">
        <div className="col-lg-3 col-md-6">
          <div className="single-footer-widget">
            <div className="logo">
              <a href="#">
                <img src="assets/img/logo2.png" alt="image" />
              </a>
            </div>
            <p>
              Lorem ipsum dolor sit amet, consec tetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
            <ul className="social">
              <li>
                <b>Follow us:</b>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i className="flaticon-twitter" />
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i className="flaticon-instagram" />
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i className="flaticon-facebook" />
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i className="flaticon-linkedin" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="single-footer-widget">
            <h3>Quick Links</h3>
            <ul className="quick-links">
              <li>
                <a href="about.html">About</a>
              </li>
              <li>
                <a href="#">Our Performance</a>
              </li>
              <li>
                <a href="faq.html">Help (FAQ)</a>
              </li>
              <li>
                <a href="news.html">Blog</a>
              </li>
              <li>
                <a href="contact.html">Contact</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="single-footer-widget">
            <h3>Other Resources</h3>
            <ul className="quick-links">
              <li>
                <a href="#">Support</a>
              </li>
              <li>
                <a href="privacy-policy.html">Privacy Policy</a>
              </li>
              <li>
                <a href="terms-condition.html">Terms of Service</a>
              </li>
              <li>
                <a href="#">Business Loans</a>
              </li>
              <li>
                <a href="#">Loan Services</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="single-footer-widget">
            <h3>Contact Us</h3>
            <div className="info-contact">
              <i className="flaticon-pin" />
              <span>6890 Blvd, The Bronx, NY 1058 New York, USA</span>
            </div>
            <div className="info-contact">
              <i className="flaticon-mail" />
              <span>
                <a href="https://templates.envytheme.com/cdn-cgi/l/email-protection#8ae2efe6e6e5caece3e4e3f2a4e9e5e7">
                  <span
                    className="__cf_email__"
                    data-cfemail="cfa7aaa3a3a08fa9a6a1a6b7e1aca0a2"
                  >
                    [email&nbsp;protected]
                  </span>
                </a>
              </span>
              <span>
                <a href="#">skype: example</a>
              </span>
            </div>
            <div className="info-contact">
              <i className="flaticon-telephone" />
              <span>
                <a href="tel:1514312-6688">+1 (514) 312-5678</a>
              </span>
              <span>
                <a href="tel:1514312-6688">+1 (514) 312-6688</a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* End Footer Area */}
  {/* Start Copy Right Area */}
  <div className="copy-right-area">
    <div className="container">
      <div className="copy-right-content">
        <p>
          Copyright @ Finix. Designed By
          <a href="https://envytheme.com/" target="_blank">
            EnvyTheme
          </a>
        </p>
      </div>
    </div>
  </div>
  {/* End Copy Right Area */}
  {/* Start Go Top Area */}
  <div className="go-top">
    <i className="bx bx-chevron-up" />
  </div>
  {/* End Go Top Area */}
  {/* Dark version */}
  <div className="dark-version">
    <label id="switch" className="switch">
      <input type="checkbox"  id="slider" />
      <span className="slider round" />
    </label>
  </div>
  {/* Dark version */}
  {/* Jquery Slim JS */}
  {/* Popper JS */}
  {/* Bootstrap JS */}
  {/* Meanmenu JS */}
  {/* Carousel JS */}
  {/* Nice Select JS */}
  {/* Magnific Popup JS */}
  {/* Odometer JS */}
  {/* Appear JS */}
  {/* Form Ajaxchimp JS */}
  {/* Form Validator JS */}
  {/* Contact JS */}
  {/* Wow JS */}
  {/* Custom JS */}
  {/* Mirrored from templates.envytheme.com/finix/default/about.html by HTTrack Website Copier/3.x [XR&CO'2014], Sun, 26 Feb 2023 16:40:34 GMT */}
</>

    );
};

export default AboutUs;