import {
    Sidebar,
    SubMenu,
    Menu,
    MenuItem,
    useProSidebar,
  } from "react-pro-sidebar";
  import { Outlet, useLocation } from "react-router-dom";
  import { Link } from "react-router-dom";
  import { faHome } from "@fortawesome/free-solid-svg-icons";
  import {
    faUser,
    faRightFromBracket,
    faChartPie,
   faUserTie,
   faUsersGear

  } from "@fortawesome/free-solid-svg-icons";
  import {
    faTabletScreenButton,
    faEnvelope,
    faSms,
  } from "@fortawesome/free-solid-svg-icons";
  
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import React from "react";
  import { useState } from "react";
  import sidebarcss from "./sidebar.css";


const AdminSidebar = () => {
  const location = useLocation();
    return (
       
              <Sidebar defaultCollapsed className="bg-white h-screen">
              <Menu
                renderExpandIcon={({ open }) => <span>{open ? "-" : "+"}</span>}
              >
               

                <MenuItem
                  className={`font-medium ${
                    window.location.pathname === "/adminhome"
                      ? " text-primary "
                      : ""
                  }`}
                  component={<Link to="/adminhome" />}
                  title="Dashboard"
                  icon={
                    <FontAwesomeIcon
                      className={` ${
                        window.location.pathname === "/adminhome"
                          ? "bg-zinc-300 px-3 py-3 rounded-lg text-primary text-xl"
                          : ""
                      }`}
                      icon={faChartPie}
                    />
                  }
                >
                  {" "}
                  Dashboard
                </MenuItem>

               

              

                <MenuItem
                  className={`font-medium ${
                    window.location.pathname === "/users"
                      ? " text-primary "
                      : ""
                  }`}
                  component={<Link to="users" />}
                  title="Loan detail"
                  icon={
                    <FontAwesomeIcon
                      className={` ${
                        window.location.pathname === "/users"
                          ? "bg-zinc-300 px-3 py-3 rounded-lg text-primary text-xl"
                          : ""
                      }`}
                      icon={faUsersGear}
                    />
                  }
                >
                  
                  Users Loan Detail
                </MenuItem>

                <MenuItem
                  className={`font-medium ${
                    window.location.pathname === "/all_registered_users"
                      ? " text-primary "
                      : ""
                  }`}
                  component={<Link to="all_registered_users" />}
                  title="all users"
                  icon={
                    <FontAwesomeIcon
                      className={` ${
                        window.location.pathname === "/all_registered_users"
                          ? "bg-zinc-300 px-3 py-3 rounded-lg text-primary text-xl"
                          : ""
                      }`}
                      icon={faUser}
                    />
                  }
                >
                  
                  Users
                </MenuItem>

                <MenuItem
                  className={`font-medium ${
                    window.location.pathname === "/all_dsa"
                      ? " text-primary "
                      : ""
                  }`}
                  component={<Link to="all_dsa" />}
                  title="users"
                  icon={
                    <FontAwesomeIcon
                      className={` ${
                        window.location.pathname === "/all_dsa"
                          ? "bg-zinc-300 px-3 py-3 rounded-lg text-primary text-xl"
                          : ""
                      }`}
                      icon={faUserTie}
                    />
                  }
                >
                  
                  All Dsa
                </MenuItem>

                <MenuItem
          className={`font-medium ${
            window.location.pathname === "/logout" ? " text-primary " : ""
          }`}
          component={<Link to="logout" />}
          title="Logout"
          icon={
            <FontAwesomeIcon
              className={` ${
                window.location.pathname === "/logout"
                  ? "bg-zinc-300 px-3 py-3 rounded-lg text-primary text-xl"
                  : ""
              }`}
              icon={faRightFromBracket}
            />
          }
        >
          Log Out
        </MenuItem>

              

                
              </Menu>
            </Sidebar>
       
    );
};

export default AdminSidebar;