import React from 'react';
import LoanStatusDiagram from '../Components/LoanStatusDiagram';

const LoanStatus = () => {
    return (
        <div>
            <LoanStatusDiagram/>
        </div>
    );
};

export default LoanStatus;