import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ToastErrorNotifications,ToastSuccessNotifications } from "../../Notifications/ToastNotifications";
import { useNavigate } from "react-router-dom";
import { updateUserDetail } from "../../Data/adminapis";
import { useSelector, useDispatch } from "react-redux";


const ProfileEdit = (prop) => {
  let navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  
  
  const Step1Schema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Name is required"),
    dob: Yup.date().required("Date of BirthRequired"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    mobile: Yup.string()
      .min(10, "Too Short!")
      .max(10, "Too Long!")
      .required("Mobile Number is required"),
    gender: Yup.string().required("Gender is required"),
    course: Yup.string().required("Course is required"),
    occupation: Yup.string().required("Occupation is required"),
    joining_year: Yup.date().required("Joining Year is required"),
    tenth: Yup.string().required("Tenth Grade is required"),
    twelth: Yup.string().required("Twelth Grade is required"),
    graduated_course: Yup.string().required("Graduated Course is required"),
    graduated_grade: Yup.string().required("Graduated Grade is required"),
  });

  const Step2Schema = Yup.object().shape({
    address: Yup.string().required("Address is required"),
    street_name: Yup.string().required("Street name is required"),
    state: Yup.string().required("State is required"),
    district: Yup.string().required("District is required"),
    city: Yup.string().required("City is required"),
    zip_code: Yup.string().required("Zip code is required"),
    father_name: Yup.string().required("Father name is required"),
    mother_name: Yup.string().required("Mother name is required"),
    father_occupation: Yup.string().required("Father occupation is required"),
    mother_occupation: Yup.string().required("Mother occupation is required"),
    family_annual_income: Yup.string().required(
      "Family annual income is required"
    ),
    parent_contact_number: Yup.string().required(
      "Parent contact number is required"
    ),
  });

  

  const onSubmitStepOne = (values) => {
    setCurrentStep(currentStep + 1);
  };

  const onSubmitStepTwo = async(values) => {
   console.log(values)
   const response = await updateUserDetail(values,prop.prop.user._id)
   if(response.success = true){
    ToastSuccessNotifications(response.message)
   }
   else{
    ToastErrorNotifications(response.message)
   }
   prop.modalFunction();
   prop.fetchUser();
  };



  return (
    
          <div>
            <div className="text-center mt-5">
              
            </div>
            <div className="flex justify-center">
              <Formik
                initialValues={{
                    name: prop.prop.fullname,
                    dob: prop.prop.dob,
                    email: prop.prop.email,
                    mobile: prop.prop.number,
                    gender: prop.prop.gender,
                    occupation: prop.prop.occupation,
                    course: prop.prop.course,
                    joining_year: prop.prop.joiningyear,
                    tenth: prop.prop.metric,
                    twelth: prop.prop.highersecondary,
                    graduated_course: prop.prop.passedcourse,
                    graduated_grade: prop.prop.passinggrade,
                    address: prop.prop.address,
                    street_name: prop.prop.address2,
                    state: prop.prop.state,
                    district: prop.prop.district,
                    city: prop.prop.city,
                    zip_code: prop.prop.zipcode,
                    father_name: prop.prop.fathersname,
                    mother_name: prop.prop.mothersname,
                    father_occupation: prop.prop.fatheroccupation,
                    mother_occupation: prop.prop.motheroccupation,
                    family_annual_income: prop.prop.familyincome,
                    parent_contact_number: prop.prop.parentnumber,
                   
                }}
                validationSchema={
                  currentStep === 1
                    ? Step1Schema
                    : Step2Schema
                   
                }
                onSubmit={(values) => {
                  currentStep === 1
                    ? onSubmitStepOne(values)
                    :  onSubmitStepTwo(values)
                  
                }}
              >
                {({ isSubmitting, errors, touched }) => (
                  <Form>
                    {currentStep === 1 && (
                      <>
                        <h2 className="text-xl">Personal Details</h2>
                        <div className="grid grid-cols-3 gap-4">
                          <div>
                            <label htmlFor="name">Name:</label>
                            <Field
                              type="text"
                              className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                              name="name"
                              placeholder="Enter Name"
                            />
                            {errors.name && touched.name ? (
                              <div className="text-red-500">{errors.name}</div>
                            ) : null}
                          </div>

                          <div>
                            <label htmlFor="dob">Date of Birth:</label>
                            <Field
                              type="date"
                              className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                              name="dob"
                              placeholder="Enter Date of Birth"
                            />
                            {errors.dob && touched.dob ? (
                              <div className="text-red-500">{errors.dob}</div>
                            ) : null}
                          </div>

                          <div>
                            <label htmlFor="email">Email:</label>
                            <Field
                              type="email"
                              className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                              name="email"
                              placeholder="Enter Email"
                            />
                            {errors.email && touched.email ? (
                              <div className="text-red-500">{errors.email}</div>
                            ) : null}
                          </div>

                          <div>
                            <label htmlFor="mobile">Mobile:</label>
                            <Field
                              type="text"
                              className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                              name="mobile"
                              placeholder="Enter Mobile"
                            />
                            {errors.mobile && touched.mobile ? (
                              <div className="text-red-500">
                                {errors.mobile}
                              </div>
                            ) : null}
                          </div>

                          <div>
                            <label htmlFor="gender">Gender:</label>
                            <Field
                              type="text"
                              className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                              name="gender"
                              placeholder="Enter Gender"
                            />
                            {errors.gender && touched.gender ? (
                              <div className="text-red-500">
                                {errors.gender}
                              </div>
                            ) : null}
                          </div>

                          <div>
                            <label htmlFor="occupation">Occupation:</label>
                            <Field
                              type="text"
                              className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                              name="occupation"
                              placeholder="Enter Occupation"
                            />
                            {errors.occupation && touched.occupation ? (
                              <div className="text-red-500">
                                {errors.occupation}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <h2 className="text-xl">Academic Details</h2>
                        <div className="grid grid-cols-3 gap-4">
                          <div>
                            <label htmlFor="joining_year">Course:</label>
                            <Field
                              type="text"
                              className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                              name="course"
                            />
                            {errors.course && touched.course ? (
                              <div className="text-red-500">
                                {errors.course}
                              </div>
                            ) : null}
                          </div>

                          <div>
                            <label htmlFor="joining_year">Joining Year:</label>
                            <Field
                              type="date"
                              className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                              name="joining_year"
                            />
                            {errors.joining_year && touched.joining_year ? (
                              <div className="text-red-500">
                                {errors.joining_year}
                              </div>
                            ) : null}
                          </div>

                          <div>
                            <label htmlFor="joining_year">10th Grade:</label>
                            <Field
                              type="text"
                              className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                              name="tenth"
                              placeholder=""
                            />
                            {errors.tenth && touched.tenth ? (
                              <div className="text-red-500">{errors.tenth}</div>
                            ) : null}
                          </div>

                          <div>
                            <label htmlFor="joining_year">12th Grade:</label>
                            <Field
                              type="text"
                              className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                              name="twelth"
                            />
                            {errors.twelth && touched.twelth ? (
                              <div className="text-red-500">
                                {errors.twelth}
                              </div>
                            ) : null}
                          </div>

                          <div>
                            <label htmlFor="joining_year">
                              Graduated Course:
                            </label>
                            <Field
                              type="text"
                              className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                              name="graduated_grade"
                            />
                            {errors.graduated_grade &&
                            touched.graduated_grade ? (
                              <div className="text-red-500">
                                {errors.graduated_grade}
                              </div>
                            ) : null}
                          </div>

                          <div>
                            <label htmlFor="joining_year">
                              Graduated with grade:
                            </label>
                            <Field
                              type="text"
                              className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                              name="graduated_course"
                            />
                            {errors.graduated_course &&
                            touched.graduated_course ? (
                              <div className="text-red-500">
                                {errors.graduated_course}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </>
                    )}
                    {currentStep === 2 && (
                      <>
                        <h2 className="text-xl">Address Details</h2>
                        <div className="grid grid-cols-3 gap-4">
                          <div>
                            <label htmlFor="address">Address:</label>
                            <Field
                              type="text"
                              className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                              name="address"
                            />
                            {errors.address && touched.address ? (
                              <div className="text-red-500">
                                {errors.address}
                              </div>
                            ) : null}
                          </div>

                          <div>
                            <label htmlFor="street_name">Street Name:</label>
                            <Field
                              type="text"
                              className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                              name="street_name"
                            />
                            {errors.street_name && touched.street_name ? (
                              <div className="text-red-500">
                                {errors.street_name}
                              </div>
                            ) : null}
                          </div>
                          <div>
                            <label htmlFor="state">State:</label>
                            <Field
                              type="text"
                              className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                              name="state"
                            />
                            {errors.state && touched.state ? (
                              <div className="text-red-500">{errors.state}</div>
                            ) : null}
                          </div>
                          <div>
                            <label htmlFor="district">District:</label>
                            <Field
                              type="text"
                              className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                              name="district"
                            />
                            {errors.district && touched.district ? (
                              <div className="text-red-500">
                                {errors.district}
                              </div>
                            ) : null}
                          </div>
                          <div>
                            <label htmlFor="city">City:</label>
                            <Field
                              type="text"
                              className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                              name="city"
                            />
                            {errors.city && touched.city ? (
                              <div className="text-red-500">{errors.city}</div>
                            ) : null}
                          </div>
                          <div>
                            <label htmlFor="city">Zip Code:</label>
                            <Field
                              type="text"
                              className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                              name="zip_code"
                            />
                            {errors.zip_code && touched.zip_code ? (
                              <div className="text-red-500">
                                {errors.zip_code}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <h2 className="text-xl">Family Details</h2>
                        <div className="grid grid-cols-3 gap-4">
                          <div>
                            <label htmlFor="father_name">Father Name:</label>
                            <div>
                              <Field
                                type="text"
                                className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                name="father_name"
                              />
                            </div>
                            {errors.father_name && touched.father_name ? (
                              <div className="text-red-500">
                                {errors.father_name}
                              </div>
                            ) : null}
                          </div>

                          <div>
                            <label htmlFor="mother_name">Mother Name:</label>
                            <div>
                              <Field
                                type="text"
                                className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                name="mother_name"
                              />
                            </div>
                            {errors.mother_name && touched.mother_name ? (
                              <div className="text-red-500">
                                {errors.mother_name}
                              </div>
                            ) : null}
                          </div>
                          <div>
                            <label htmlFor="father_occupation">
                              Father Occupation:
                            </label>
                            <div>
                              <Field
                                type="text"
                                className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                name="father_occupation"
                              />
                            </div>
                            {errors.father_occupation &&
                            touched.father_occupation ? (
                              <div className="text-red-500">
                                {errors.father_occupation}
                              </div>
                            ) : null}
                          </div>
                          <div>
                            <label htmlFor="mother_occupation">
                              Mother Occupation:
                            </label>
                            <div>
                              <Field
                                type="text"
                                className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                name="mother_occupation"
                              />
                            </div>
                            {errors.mother_occupation &&
                            touched.mother_occupation ? (
                              <div className="text-red-500">
                                {errors.mother_occupation}
                              </div>
                            ) : null}
                          </div>
                          <div>
                            <label htmlFor="family_annual_income">
                              Family Annual Income:
                            </label>
                            <div>
                              <Field
                                type="text"
                                className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                name="family_annual_income"
                              />
                            </div>
                            {errors.family_annual_income &&
                            touched.family_annual_income ? (
                              <div className="text-red-500">
                                {errors.family_annual_income}
                              </div>
                            ) : null}
                          </div>
                          <div>
                            <label htmlFor="parent_contact_number">
                              Parent Contact Number:
                            </label>
                            <div>
                              <Field
                                type="text"
                                className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                name="parent_contact_number"
                              />
                            </div>
                            {errors.parent_contact_number &&
                            touched.parent_contact_number ? (
                              <div className="text-red-500">
                                {errors.parent_contact_number}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </>
                    )}
                   

                    <div className="flex justify-center mt-5">
                      <button
                        type="button"
                        className="btn mx-3 border border-primary font-medium text-primary hover:bg-primary hover:text-white focus:bg-primary focus:text-white active:bg-primary/90 dark:border-accent dark:text-accent-light dark:hover:bg-accent dark:hover:text-white dark:focus:bg-accent dark:focus:text-white dark:active:bg-accent/90"
                        onClick={() => setCurrentStep(currentStep - 1)}
                        disabled={currentStep === 1}
                      >
                        Prev
                      </button>
                      <button
                        type="submit"
                        className="btn  mx-3 border border-primary font-medium text-primary hover:bg-primary hover:text-white focus:bg-primary focus:text-white active:bg-primary/90 dark:border-accent dark:text-accent-light dark:hover:bg-accent dark:hover:text-white dark:focus:bg-accent dark:focus:text-white dark:active:bg-accent/90"
                        onClick={() => {}}
                      >
                       {currentStep ==1 ?"Next" : "Update"}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
      
  );
};

export default ProfileEdit;
