import React from "react";
import { Helmet } from "react-helmet";
import Navbar from "./Component/Navbar";
const PrivacyPolicy = () => {
  return (
    <>
      {/* Mirrored from templates.envytheme.com/finix/default/privacy-policy.html by HTTrack Website Copier/3.x [XR&CO'2014], Sun, 26 Feb 2023 16:40:40 GMT */}
      {/* Required meta tags */}
      

      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        {/* Bootstrap CSS */}
        <link rel="stylesheet" href="assets/css/bootstrap.min.css" />
        {/* Animate CSS */}
        <link rel="stylesheet" href="assets/css/animate.min.css" />
        {/* Meanmenu CSS */}
        <link rel="stylesheet" href="assets/css/meanmenu.css" />
        {/* Boxicons CSS */}
        <link rel="stylesheet" href="assets/css/boxicons.min.css" />
        {/* Flaticon CSS */}
        <link rel="stylesheet" href="assets/css/flaticon.css" />
        {/* Carousel CSS */}
        <link rel="stylesheet" href="assets/css/owl.carousel.min.css" />
        {/* Carousel Default CSS */}
        <link rel="stylesheet" href="assets/css/owl.theme.default.min.css" />
        {/* Magnific Popup CSS */}
        <link rel="stylesheet" href="assets/css/magnific-popup.min.css" />
        {/* Nice Select CSS */}
        <link rel="stylesheet" href="assets/css/nice-select.min.css" />
        {/* Odometer CSS */}
        <link rel="stylesheet" href="assets/css/odometer.min.css" />
        {/* Style CSS */}
        <link rel="stylesheet" href="assets/css/style.css" />
        {/* Dark CSS */}
        <link rel="stylesheet" href="assets/css/dark.css" />
        {/* Responsive CSS */}
        <link rel="stylesheet" href="assets/css/responsive.css" />
        <title>Finix - Loans &amp; Funding HTML Template</title>
        <link rel="icon" type="image/png" href="assets/img/favicon.png" />
      </Helmet>

      <Navbar />
      
      {/* End Navbar Area */}
      {/* Start Page Title Area */}
      <div className="page-title-area item-bg-1">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Privacy Policy</h2>
                <ul>
                  <li>
                    <a href="index.html">Home</a>
                  </li>
                  <li>Privacy Policy</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Page Title Area */}
      {/* Start Privacy Policy Area */}
      <section className="terms-of-condition-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="terms-of-condition-content">
                <img src="assets/img/privacy-policy.jpg" alt="image" />
                <h3>Welcome to Finix Privacy Policy</h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged.
                </p>
                <h3>Welcome to Finix Privacy Policy</h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged.
                </p>
                <h3>Welcome to Finix Privacy Policy</h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged.
                </p>
                <h3>Welcome to Finix Privacy Policy</h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="terms-of-condition-sidebar">
                <ul>
                  <li>
                    <a href="#">Agricultural Loan</a>
                  </li>
                  <li>
                    <a href="#">Business Loan</a>
                  </li>
                  <li>
                    <a href="#">House Loan</a>
                  </li>
                  <li>
                    <a href="#">Personal Loan</a>
                  </li>
                  <li>
                    <a href="#">Education Loan</a>
                  </li>
                  <li>
                    <a href="#">Payday Loan</a>
                  </li>
                  <li>
                    <a href="#">Vehicle loan</a>
                  </li>
                  <li>
                    <a href="#">Medical loan</a>
                  </li>
                  <li>
                    <a href="#">StartUp loan</a>
                  </li>
                  <li>
                    <a href="privacy-policy.html">Privacy Policy</a>
                  </li>
                  <li className="active">
                    <a href="#">Terms Condition</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Privacy Policy Area */}
      {/* Start Footer Area */}
      <section className="footer-area pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="single-footer-widget">
                <div className="logo">
                  <a href="#">
                    <img src="assets/img/logo2.png" alt="image" />
                  </a>
                </div>
                <p>
                  Lorem ipsum dolor sit amet, consec tetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
                <ul className="social">
                  <li>
                    <b>Follow us:</b>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      <i className="flaticon-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      <i className="flaticon-instagram" />
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      <i className="flaticon-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      <i className="flaticon-linkedin" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-footer-widget">
                <h3>Quick Links</h3>
                <ul className="quick-links">
                  <li>
                    <a href="about.html">About</a>
                  </li>
                  <li>
                    <a href="#">Our Performance</a>
                  </li>
                  <li>
                    <a href="faq.html">Help (FAQ)</a>
                  </li>
                  <li>
                    <a href="news.html">Blog</a>
                  </li>
                  <li>
                    <a href="contact.html">Contact</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-footer-widget">
                <h3>Other Resources</h3>
                <ul className="quick-links">
                  <li>
                    <a href="#">Support</a>
                  </li>
                  <li>
                    <a href="privacy-policy.html">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="terms-condition.html">Terms of Service</a>
                  </li>
                  <li>
                    <a href="#">Business Loans</a>
                  </li>
                  <li>
                    <a href="#">Loan Services</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-footer-widget">
                <h3>Contact Us</h3>
                <div className="info-contact">
                  <i className="flaticon-pin" />
                  <span>6890 Blvd, The Bronx, NY 1058 New York, USA</span>
                </div>
                <div className="info-contact">
                  <i className="flaticon-mail" />
                  <span>
                    <a href="https://templates.envytheme.com/cdn-cgi/l/email-protection#5a323f3636351a3c3334332274393537">
                      <span
                        className="__cf_email__"
                        data-cfemail="422a272e2e2d02242b2c2b3a6c212d2f"
                      >
                        [email&nbsp;protected]
                      </span>
                    </a>
                  </span>
                  <span>
                    <a href="#">skype: example</a>
                  </span>
                </div>
                <div className="info-contact">
                  <i className="flaticon-telephone" />
                  <span>
                    <a href="tel:1514312-6688">+1 (514) 312-5678</a>
                  </span>
                  <span>
                    <a href="tel:1514312-6688">+1 (514) 312-6688</a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Footer Area */}
      {/* Start Copy Right Area */}
      <div className="copy-right-area">
        <div className="container">
          <div className="copy-right-content">
            <p>
              Copyright @ Finix. Designed By
              <a href="https://envytheme.com/" target="_blank">
                EnvyTheme
              </a>
            </p>
          </div>
        </div>
      </div>
      {/* End Copy Right Area */}
      {/* Start Go Top Area */}
      <div className="go-top">
        <i className="bx bx-chevron-up" />
      </div>
      {/* End Go Top Area */}
      {/* Dark version */}
      <div className="dark-version">
        <label id="switch" className="switch">
          <input type="checkbox" id="slider" />
          <span className="slider round" />
        </label>
      </div>
      {/* Dark version */}
      {/* Jquery Slim JS */}
      {/* Popper JS */}
      {/* Bootstrap JS */}
      {/* Meanmenu JS */}
      {/* Carousel JS */}
      {/* Nice Select JS */}
      {/* Magnific Popup JS */}
      {/* Odometer JS */}
      {/* Appear JS */}
      {/* Form Ajaxchimp JS */}
      {/* Form Validator JS */}
      {/* Contact JS */}
      {/* Wow JS */}
      {/* Custom JS */}
      {/* Mirrored from templates.envytheme.com/finix/default/privacy-policy.html by HTTrack Website Copier/3.x [XR&CO'2014], Sun, 26 Feb 2023 16:40:41 GMT */}
    </>
  );
};

export default PrivacyPolicy;
