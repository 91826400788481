import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Modal from "react-bootstrap/Modal";
import { getUsers, createUser, deleteUser } from "../../Data/adminapis";
import {
  ToastErrorNotifications,
  ToastSuccessNotifications,
} from "../../Notifications/ToastNotifications";
import moment from "moment";
import { faTrash, faExpand } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Users = () => {
  const [users, setUsers] = useState("");
  const [show, setShow] = useState(false);

  const [deleteShow, setDeleteShow] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const setDeleteModal = (id) => {
    setDeleteId(id);
    setDeleteShow(true);
  };

  const fetchUsers = async () => {
    const response = await getUsers();
    setUsers(response.data);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  //add new student
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    number: Yup.string()
      .matches(/^\d+$/, "Invalid number")
      .min(10, "Mobile number must be at least 10 digits")
      .max(10, "Mobile number must be at most 10 digits")
      .required("Number is required"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters"),
  });

  const initialValues = {
    name: "",
    email: "",
    number: "",
    password: "",
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    console.log(values);
    setSubmitting(false);
    const response = await createUser(values);
    if (response.success === true) {
      ToastSuccessNotifications("New User has been created successfully");
      setShow(false);
      fetchUsers();
    } else {
      ToastErrorNotifications("Failed, Error Occured");
    }
  };

  // delete function
  const deleteUserButton = async (userid) => {
    //api call
    const deleteUserCall = await deleteUser(userid);
    setDeleteShow(false);
    if (deleteUserCall.success == true) {
      ToastSuccessNotifications("User deleted successfully");
    } else {
      ToastErrorNotifications("Error Occured, Try again");
    }

    fetchUsers();
  };

  return (
    <div className="w-full">
      <div className="mx-5 mt-8 mb-3">
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg ">
          <div
            className="is-scrollbar-hidden min-w-full overflow-x-auto"
            style={{ width: "" }}
          >
            <button
              className="mx-3 mb-3 btn rounded-full bg-success font-medium text-white hover:bg-success-focus focus:bg-success-focus active:bg-success-focus/90"
              onClick={() => setShow(true)}
            >
              Add New
            </button>
            <table className="mr-8 is-hoverable w-full text-left">
              <thead>
                <tr>
                  <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                    #
                  </th>
                  <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                    Name
                  </th>
                  <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                    Email
                  </th>
                  <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                    Phone no.
                  </th>
                  <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                    Date
                  </th>
                  <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                    Activation Form Filled
                  </th>
                  <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {users.length > 0 &&
                  users.map((item, index) => (
                    <tr
                      key={item._id}
                      className="border border-transparent border-b-slate-200 dark:border-b-navy-500"
                    >
                      <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                        {index + 1}
                      </td>

                      <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                        {item.name}
                      </td>
                      <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                        {item.email}
                      </td>
                      <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                        {item.number}
                      </td>
                      <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                        <h5 className="font-semibold text-sm text-green-500">
                          {moment(item.date).format("D-M-YYYY hh:mm:ss")}{" "}
                        </h5>
                      </td>
                      {item.activation_form == 1 ? (
                        <td className="whitespace-nowrap px-4 py-3 sm:px-5 ">
                          <div className="badge bg-success text-white">Yes</div>
                        </td>
                      ) : (
                        <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                          <div className="badge bg-error text-white">No</div>
                        </td>
                      )}

                      <td className="whitespace-nowrap px-4 py-3 sm:px-5 text-yellow-600">
                        <button
                          className="mx-3 btn rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90"
                          onClick={() => setDeleteModal(item._id)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* //add new modal */}
      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Add New User
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="" style={{ width: "500px" }}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, isSubmitting }) => (
                <Form>
                  <div>
                    <label htmlFor="name">Name:</label>
                    <Field
                      type="text"
                      className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                      name="name"
                    />
                    {errors.name && touched.name ? (
                      <div className="text-red-500">{errors.name}</div>
                    ) : null}
                  </div>
                  <div>
                    <label htmlFor="name">Email:</label>
                    <Field
                      type="text"
                      className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                      name="email"
                    />
                    {errors.email && touched.email ? (
                      <div className="text-red-500">{errors.email}</div>
                    ) : null}
                  </div>
                  <div>
                    <label htmlFor="name">Number:</label>
                    <Field
                      type="text"
                      className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                      name="number"
                    />
                    {errors.number && touched.number ? (
                      <div className="text-red-500">{errors.number}</div>
                    ) : null}
                  </div>
                  <div>
                    <label htmlFor="name">Password:</label>
                    <Field
                      type="text"
                      className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                      name="password"
                    />
                    {errors.password && touched.password ? (
                      <div className="text-red-500">{errors.password}</div>
                    ) : null}
                  </div>

                  <div className="flex justify-center">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="btn mx-3 my-3 border border-primary font-medium text-primary hover:bg-primary hover:text-white focus:bg-primary focus:text-white active:bg-primary/90 dark:border-accent dark:text-accent-light dark:hover:bg-accent dark:hover:text-white dark:focus:bg-accent dark:focus:text-white dark:active:bg-accent/90"
                    >
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>

      {/* //delete modal */}
      <Modal
        show={deleteShow}
        onHide={() => setDeleteShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            <h5 className="text-sm font-semibold">
              {" "}
              Are you sure you want to delete ?
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="flex justify-center">
            <button
              onClick={() => deleteUserButton(deleteId)}
              class="btn mx-2 bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
            >
              Yes
            </button>
            <button
              onClick={() => setDeleteShow(false)}
              class="btn mx-2 bg-slate-150 font-medium text-slate-800 hover:bg-slate-200 focus:bg-slate-200 active:bg-slate-200/80 dark:bg-navy-500 dark:text-navy-50 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Users;
