import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import {
  faTrash,
  faExpand,
  faEye,
  faCheck,
  faBan,
  faArrowsRotate,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import {
  ToastErrorNotifications,
  ToastSuccessNotifications,
} from "../../Notifications/ToastNotifications";

import { getFiles,updateFileStatus,getUserInfo,fetchImageDsa} from "../../Data/dsaapis";

const DsaUserFiles = () => {
  const host = "http://localhost:5000";
  const { id } = useParams();
  const [userInfo, setuserInfo] = useState("");
  const [userFiles, setUserFiles] = useState("");
  const [show, setShow] = useState(false);
  const [modalImage, setModalImage] = useState("");

  const setModal = async (path) => {
    const result = await fetchImageDsa(`/${path}`)
    setModalImage(result);
    setShow(true);
  };

  const fetch = async() => {
    const response =await getFiles(id);
    setUserFiles(response.data)
    const userapiresponse =await getUserInfo(id);
    setuserInfo(userapiresponse.data)
  };

  useEffect(() => {
    fetch();
  }, []);

  const FileStatusUpdate = async (id, status) => {
    console.log(id, status);
    const response = await updateFileStatus(id, status);
    if (response.success == true) {
      ToastSuccessNotifications(response.message);
      fetch();
    } else {
      ToastErrorNotifications(response.message);
    }
  };

  return <div className="w-full">
  <div className="mx-5 mt-8 mb-3">
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg ">
      <div
        className="is-scrollbar-hidden min-w-full overflow-x-auto"
        style={{ width: "" }}
      >

<h5 className="text-xl text-center my-8">User Info</h5>
        <div className="mx-5 mb-8" style={{ minWidth: "" }}>
          <table className="is-hoverable w-full text-left">
            <thead>
              <tr>
                
                <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                  Name
                </th>
               
                
              </tr>
            </thead>
            <tbody>
              {userInfo.length !='' &&
               
                  <tr
                    
                    className="border border-transparent border-b-slate-200 dark:border-b-navy-500"
                  >
                    
                    <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                      {userInfo.name}
                    </td>
                  
                   

                 
                  </tr>
                }
            </tbody>
          </table>
        </div>
       


        <h5 className="text-xl text-center my-8">Uploaded Documents</h5>
        <div className="mx-5 mb-8" style={{ minWidth: "" }}>
          <table className="is-hoverable w-full text-left">
            <thead>
              <tr>
                <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                  #
                </th>
                <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                  File
                </th>
                <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                  Document
                </th>
                <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                  Size
                </th>
                <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                  Created On
                </th>
                <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                  FileName
                </th>
                <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                  Status
                </th>
                <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {userFiles.length > 0 &&
                userFiles.map((item, index) => (
                  <tr
                    key={item._id}
                    className="border border-transparent border-b-slate-200 dark:border-b-navy-500"
                  >
                    <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                      {index + 1}
                    </td>
                    <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                      <button
                        className="mx-3 btn rounded-full bg-success font-medium text-white hover:bg-success-focus focus:bg-success-focus active:bg-success-focus/90"
                        onClick={() => setModal(item.filePath)}
                      >
                        <FontAwesomeIcon icon={faExpand} />
                      </button>
                    </td>
                    <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                      {item.title}
                    </td>
                    <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                      {item.fileSize}
                    </td>
                    <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                      {moment(item.createdAt).format("D-M-YYYY")}
                    </td>
                    <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                      {item.fileName}
                    </td>
                    <td className="whitespace-nowrap px-4 py-3 sm:px-5 ">
                      {item.status == "Approved" ? (
                        <span className="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded ">
                          {item.status}
                        </span>
                      ) : item.status == "Declined" ? (
                        <span className="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded ">
                          {item.status}
                        </span>
                      ) : (
                        <span className="bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded ">
                          {item.status}
                        </span>
                      )}
                    </td>

                    <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                      <button
                        onClick={() => {
                          FileStatusUpdate(item._id, "Approved");
                        }}
                      >
                        <FontAwesomeIcon
                          className="mx-2 rounded-md border border-red-400 border-dotted px-2 py-1 text-lg text-blue-600"
                          icon={faCheck}
                        />
                      </button>

                      <button
                        onClick={() => {
                          FileStatusUpdate(item._id, "Declined");
                        }}
                      >
                        <FontAwesomeIcon
                          className="mx-2 rounded-md border border-red-400 border-dotted px-2 py-1 text-lg text-red-600"
                          icon={faBan}
                        />
                      </button>

                      <button
                        onClick={() => {
                          FileStatusUpdate(item._id, "Pending");
                        }}
                      >
                        <FontAwesomeIcon
                          className="mx-2 rounded-md border border-red-400 border-dotted px-2 py-1 text-lg text-yellow-600"
                          icon={faArrowsRotate}
                        />
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <Modal
          show={show}
          size="lg"
          onHide={() => setShow(false)}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              Full Image
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="flex justify-center">
              <iframe
                src={modalImage}
                title="png"
                className="frame"
                height="800px"
                width="1000px"
              ></iframe>
            </div>
          </Modal.Body>
        </Modal>

       

       
      </div>
    </div>
  </div>
</div>;
};

export default DsaUserFiles;
