import React from "react";
import { Helmet } from "react-helmet";
import Navbar from "./Component/Navbar";

const Contact = () => {
  return (
    <>
      {/* Mirrored from templates.envytheme.com/finix/default/contact.html by HTTrack Website Copier/3.x [XR&CO'2014], Sun, 26 Feb 2023 16:40:41 GMT */}
      {/* Required meta tags */}
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        {/* Bootstrap CSS */}
        <link rel="stylesheet" href="assets/css/bootstrap.min.css" />
        {/* Animate CSS */}
        <link rel="stylesheet" href="assets/css/animate.min.css" />
        {/* Meanmenu CSS */}
        <link rel="stylesheet" href="assets/css/meanmenu.css" />
        {/* Boxicons CSS */}
        <link rel="stylesheet" href="assets/css/boxicons.min.css" />
        {/* Flaticon CSS */}
        <link rel="stylesheet" href="assets/css/flaticon.css" />
        {/* Carousel CSS */}
        <link rel="stylesheet" href="assets/css/owl.carousel.min.css" />
        {/* Carousel Default CSS */}
        <link rel="stylesheet" href="assets/css/owl.theme.default.min.css" />
        {/* Magnific Popup CSS */}
        <link rel="stylesheet" href="assets/css/magnific-popup.min.css" />
        {/* Nice Select CSS */}
        <link rel="stylesheet" href="assets/css/nice-select.min.css" />
        {/* Odometer CSS */}
        <link rel="stylesheet" href="assets/css/odometer.min.css" />
        {/* Style CSS */}
        <link rel="stylesheet" href="assets/css/style.css" />
        {/* Dark CSS */}
        <link rel="stylesheet" href="assets/css/dark.css" />
        {/* Responsive CSS */}
        <link rel="stylesheet" href="assets/css/responsive.css" />
        <title>Finix - Loans &amp; Funding HTML Template</title>
        <link rel="icon" type="image/png" href="assets/img/favicon.png" />
      </Helmet>
      <Navbar />
    
      {/* End Navbar Area */}
      {/* Start Page Title Area */}
      <div className="page-title-area item-bg-1">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Contact</h2>
                <ul>
                  <li>
                    <a href="index.html">Home</a>
                  </li>
                  <li>Contact</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Page Title Area */}
      {/* Start Contact Area */}
      <section className="contact-area ptb-100">
        <div className="container">
          <div className="section-title">
            <span>Let's discuss</span>
            <h2>Whatever question you have, please feel free to ask.</h2>
          </div>
          <div className="row">
            <div className="col-lg-8">
              <div className="contact-form">
                <div className="title">
                  <h3>Write Us</h3>
                </div>
                <form id="contactForm">
                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <label>Name</label>
                        <input
                          type="text"
                          name="name"
                          id="name"
                          className="form-control"
                          required=""
                          data-error="Please enter your name"
                        />
                        <div className="help-block with-errors" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <label>Email Address</label>
                        <input
                          type="email"
                          name="email"
                          id="email"
                          className="form-control"
                          required=""
                          data-error="Please enter your email"
                        />
                        <div className="help-block with-errors" />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label>Subject</label>
                        <input
                          type="text"
                          name="msg_subject"
                          id="msg_subject"
                          className="form-control"
                          required=""
                          data-error="Please enter your subject"
                        />
                        <div className="help-block with-errors" />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label>Message</label>
                        <textarea
                          name="message"
                          className="form-control"
                          id="message"
                          cols={30}
                          rows={5}
                          required=""
                          data-error="Write your message"
                          defaultValue={""}
                        />
                        <div className="help-block with-errors" />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <button type="submit" className="default-btn">
                        Send message
                        <span />
                      </button>
                      <div id="msgSubmit" className="h3 text-center hidden" />
                      <div className="clearfix" />
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="contact-side-box">
                <div className="title">
                  <h3>Contact Finix</h3>
                </div>
                <div className="info-box">
                  <div className="icon">
                    <i className="flaticon-clock" />
                  </div>
                  <h4>Working Hours</h4>
                  <ul className="list">
                    <li>
                      Mon – Thurs
                      <span>8:00 AM - 5:00 PM</span>
                    </li>
                    <li>
                      Fri – Satur
                      <span>8:00 AM - 3:00 PM</span>
                    </li>
                    <li>
                      Sun
                      <span>CLOSED</span>
                    </li>
                  </ul>
                </div>
                <div className="info-box">
                  <div className="icon">
                    <i className="flaticon-pin" />
                  </div>
                  <h4>Address</h4>
                  <span>
                    6890 Blvd, The Bronx, NY 1058 New <br /> York, USA
                  </span>
                </div>
                <div className="info-box">
                  <div className="icon">
                    <i className="flaticon-phone-call" />
                  </div>
                  <h4>Phone</h4>
                  <span>
                    <a href="tel:1514312-6688">+1 (514) 312-5678</a>
                  </span>
                  <span>
                    <a href="tel:1514312-6688">+1 (514) 312-6688</a>
                  </span>
                </div>
                <div className="info-box">
                  <div className="icon">
                    <i className="flaticon-email" />
                  </div>
                  <h4>Email</h4>
                  <span>
                    <a href="https://templates.envytheme.com/cdn-cgi/l/email-protection#741c1118181b34121d1a1d0c5a171b19">
                      <span
                        className="__cf_email__"
                        data-cfemail="e8808d848487a88e81868190c68b8785"
                      >
                        [email&nbsp;protected]
                      </span>
                    </a>
                  </span>
                  <span>
                    <a href="#">skype: example</a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Contact Area */}
      {/* Start Map Area */}
      <div className="map">
        <div className="container-fluid">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d193595.15830869428!2d-74.11976397304603!3d40.69766374874431!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sbd!4v1594738801148!5m2!1sen!2sbd" />
        </div>
      </div>
      {/* End Map Area */}
      {/* Start Footer Area */}
      <section className="footer-area pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="single-footer-widget">
                <div className="logo">
                  <a href="#">
                    <img src="assets/img/logo2.png" alt="image" />
                  </a>
                </div>
                <p>
                  Lorem ipsum dolor sit amet, consec tetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
                <ul className="social">
                  <li>
                    <b>Follow us:</b>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      <i className="flaticon-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      <i className="flaticon-instagram" />
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      <i className="flaticon-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      <i className="flaticon-linkedin" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-footer-widget">
                <h3>Quick Links</h3>
                <ul className="quick-links">
                  <li>
                    <a href="about.html">About</a>
                  </li>
                  <li>
                    <a href="#">Our Performance</a>
                  </li>
                  <li>
                    <a href="faq.html">Help (FAQ)</a>
                  </li>
                  <li>
                    <a href="news.html">Blog</a>
                  </li>
                  <li>
                    <a href="contact.html">Contact</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-footer-widget">
                <h3>Other Resources</h3>
                <ul className="quick-links">
                  <li>
                    <a href="#">Support</a>
                  </li>
                  <li>
                    <a href="privacy-policy.html">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="terms-condition.html">Terms of Service</a>
                  </li>
                  <li>
                    <a href="#">Business Loans</a>
                  </li>
                  <li>
                    <a href="#">Loan Services</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-footer-widget">
                <h3>Contact Us</h3>
                <div className="info-contact">
                  <i className="flaticon-pin" />
                  <span>6890 Blvd, The Bronx, NY 1058 New York, USA</span>
                </div>
                <div className="info-contact">
                  <i className="flaticon-mail" />
                  <span>
                    <a href="https://templates.envytheme.com/cdn-cgi/l/email-protection#1c74797070735c7a75727564327f7371">
                      <span
                        className="__cf_email__"
                        data-cfemail="2e464b4242416e4847404756004d4143"
                      >
                        [email&nbsp;protected]
                      </span>
                    </a>
                  </span>
                  <span>
                    <a href="#">skype: example</a>
                  </span>
                </div>
                <div className="info-contact">
                  <i className="flaticon-telephone" />
                  <span>
                    <a href="tel:1514312-6688">+1 (514) 312-5678</a>
                  </span>
                  <span>
                    <a href="tel:1514312-6688">+1 (514) 312-6688</a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Footer Area */}
      {/* Start Copy Right Area */}
      <div className="copy-right-area">
        <div className="container">
          <div className="copy-right-content">
            <p>
              Copyright @ Finix. Designed By
              <a href="https://envytheme.com/" target="_blank">
                EnvyTheme
              </a>
            </p>
          </div>
        </div>
      </div>
      {/* End Copy Right Area */}
      {/* Start Go Top Area */}
      <div className="go-top">
        <i className="bx bx-chevron-up" />
      </div>
      {/* End Go Top Area */}
      {/* Dark version */}
      <div className="dark-version">
        <label id="switch" className="switch">
          <input type="checkbox"  id="slider" />
          <span className="slider round" />
        </label>
      </div>
      {/* Dark version */}
      {/* Jquery Slim JS */}
      {/* Popper JS */}
      {/* Bootstrap JS */}
      {/* Meanmenu JS */}
      {/* Carousel JS */}
      {/* Nice Select JS */}
      {/* Magnific Popup JS */}
      {/* Odometer JS */}
      {/* Appear JS */}
      {/* Form Ajaxchimp JS */}
      {/* Form Validator JS */}
      {/* Contact JS */}
      {/* Wow JS */}
      {/* Custom JS */}
      {/* Mirrored from templates.envytheme.com/finix/default/contact.html by HTTrack Website Copier/3.x [XR&CO'2014], Sun, 26 Feb 2023 16:40:41 GMT */}
    </>
  );
};

export default Contact;
