import React, { useState, useEffect } from "react";
import {
  getAllUsers,
  updateLoanStatus,
  getDsas,
  assignDsa,
  createUser,
} from "../../Data/adminapis";
import { Link } from "react-router-dom";
import {
  faEye,
  faCheck,
  faBan,
  faArrowsRotate,
} from "@fortawesome/free-solid-svg-icons";
import {
  ToastErrorNotifications,
  ToastSuccessNotifications,
} from "../../Notifications/ToastNotifications";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const AllUsers = () => {
  const [users, setUsers] = useState("");
  const [dsas, setdsas] = useState("");
  const [selectedDsa, setselectedDsa] = useState("");
  const [selectedIds, setselectedIds] = useState([]);

  

  const fetchUsers = async () => {
    const response = await getAllUsers();
    setUsers(response.data);
    const dsasresponse = await getDsas();
    console.log(dsasresponse);
    setdsas(dsasresponse.data);
    setselectedDsa(dsasresponse.data[0]._id);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const LoanStatusUpdate = async (id, status) => {
    console.log(id, status);
    const response = await updateLoanStatus(id, status);
    if (response.success == true) {
      ToastSuccessNotifications(response.message);
      fetchUsers();
    } else {
      ToastErrorNotifications(response.message);
    }
  };

  const onChangeCheckbox = (e) => {
    console.log(e.target.value);
    // Destructuring
    const { value, checked } = e.target;

    // Case 1 : The user checks the box
    if (checked) {
      console.log("case checked");
      setselectedIds((arr) => [...arr, value]);
    }

    // Case 2  : The user unchecks the box
    else {
      console.log("not checked");
      const index = selectedIds.indexOf(value);
      if (index > -1) {
        // only splice array when item is found
        setselectedIds(selectedIds.filter((item) => item !== value)); // 2nd parameter means remove one item only
      }
    }
    console.log(selectedIds, selectedIds.length);
  };

  const onChangeSetDsa = (e) => {
    console.log(e.target.value, "selected");
    setselectedDsa(e.target.value);
  };

  const SubmitDsa = async (e) => {
    const response = await assignDsa(selectedIds, selectedDsa);
    if (response.success == true) {
      ToastSuccessNotifications(response.message);
      setselectedIds([]);
    } else {
      ToastErrorNotifications(response.message);
    }
    fetchUsers()
  };

  

  return (
    <div className="w-full">
      <div className="mx-5 mt-8 mb-3">
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg ">
          <div
            className="is-scrollbar-hidden min-w-full overflow-x-auto"
            style={{ width: "" }}
          >
            <div className="flex">
              {selectedIds.length > 0 && (
                <div className="mx-3 my-3" style={{ width: "300px" }}>
                  <div className="flex">
                    <label className="block">
                      <span>Select DSA</span>
                      <select
                        onChange={(e) => {
                          onChangeSetDsa(e);
                        }}
                        className="form-select mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                      >
                        {dsas != "" &&
                          dsas.map((item) => (
                            <option key={item._id} value={item._id}>
                              {item.name}
                            </option>
                          ))}
                      </select>
                    </label>

                    <button
                      onClick={(e) => {
                        SubmitDsa(e);
                      }}
                      className="btn bg-primary mx-3 mt-6  h-10 font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              )}
             
            </div>

            <div
              style={{
                position: "relative",
              }}
            >
              <table
                style={{
                  position: "fixed",
                  top: "200px",
                  width: "90%",
                }}
                className="mr-8 is-hoverable w-full text-left"
              >
                <thead>
                  <tr>
                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                      #
                    </th>
                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                      Name
                    </th>
                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                      Email
                    </th>
                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                      Phone no.
                    </th>
                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                      Assigned Dsa
                    </th>
                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                      Loan Status
                    </th>
                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {users.length > 0 &&
                    users.map((item, index) => (
                      <tr
                        key={item._id}
                        className="border border-transparent border-b-slate-200 dark:border-b-navy-500"
                      >
                        <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                          <input
                            className="form-checkbox mr-3  is-basic h-5 w-5 rounded border-slate-400/70 checked:bg-primary checked:border-primary hover:border-primary focus:border-primary dark:border-navy-400 dark:checked:bg-accent dark:checked:border-accent dark:hover:border-accent dark:focus:border-accent"
                            type="checkbox"
                            value={item._id}
                            onChange={(e) => {
                              onChangeCheckbox(e);
                            }}
                          />
                          {index + 1}
                        </td>

                        <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                          {item.fullname}
                        </td>
                        <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                          {item.user.email}
                        </td>
                        <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                          {item.user.number}
                        </td>
                        <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                        <h5 className="text-sm text-green-600 font-semibold"> {item.assigned_dsa && item.assigned_dsa.name}</h5>  
                        </td>
                        {item.status == "Approved" ? (
                          <td className=" whitespace-nowrap px-4 py-3 sm:px-5">
                            <span className="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded ">
                              {item.status}
                            </span>
                          </td>
                        ) : item.status == "Declined" ? (
                          <td className=" whitespace-nowrap px-4 py-3 sm:px-5">
                            <span className="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded ">
                              {item.status}
                            </span>
                          </td>
                        ) : (
                          <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                            <span className="bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded ">
                              {item.status}
                            </span>
                          </td>
                        )}

                        <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                          <Link to={`/user/${item._id}`}>
                            <button>
                              <FontAwesomeIcon
                                className="mx-2 rounded-md border border-red-400 border-dotted px-2 py-1 text-lg text-green-600"
                                icon={faEye}
                              />
                            </button>
                          </Link>

                          <button
                            onClick={() => {
                              LoanStatusUpdate(item._id, "Approved");
                            }}
                          >
                            <FontAwesomeIcon
                              className="mx-2 rounded-md border border-red-400 border-dotted px-2 py-1 text-lg text-blue-600"
                              icon={faCheck}
                            />
                          </button>

                          <button
                            onClick={() => {
                              LoanStatusUpdate(item._id, "Declined");
                            }}
                          >
                            <FontAwesomeIcon
                              className="mx-2 rounded-md border border-red-400 border-dotted px-2 py-1 text-lg text-red-600"
                              icon={faBan}
                            />
                          </button>

                          <button
                            onClick={() => {
                              LoanStatusUpdate(item._id, "Pending");
                            }}
                          >
                            <FontAwesomeIcon
                              className="mx-2 rounded-md border border-red-400 border-dotted px-2 py-1 text-lg text-yellow-600"
                              icon={faArrowsRotate}
                            />
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>

           
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllUsers;
