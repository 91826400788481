import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import DsaSidebar from "./Sidebar";
import DsaTopbar from "./Topbar";

const DsaLayout = () => {
  return (
    <div className="flex">
      <aside className="h-screen sticky top-0" style={{}}>
        <DsaSidebar />
      </aside>

      <main className="w-full">
        <aside className="w-full sticky top-0" style={{ zIndex: "998" }}>
          <DsaTopbar />
        </aside>
        <Outlet />
      </main>
    </div>
  );
};

export default DsaLayout;
