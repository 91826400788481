import React, { useState ,useEffect} from "react";
import { Navigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ToastErrorNotifications } from "./Notifications/ToastNotifications";

const AdminComponent = ({ children }) => {
  let navigate = useNavigate();
  const [jwt, setJwt] = useState(localStorage.getItem("token"));
  const [user, setUser] = useState(localStorage.getItem("user_admin"));
 

if(user == 1){
   
    return jwt ? <Outlet/> : <Navigate to="/admin" />;
}
else{
    
    return  <Navigate to="/admin" />;
}
}
  


export default AdminComponent;
