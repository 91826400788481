import React, { useState, useEffect } from "react";
import {
  ToastErrorNotifications,
  ToastSuccessNotifications,
} from "../../Notifications/ToastNotifications";
import { fetchReferralCode, sendReferralEmail } from "../../Data/apis";
import referralImage from "../../Images/referral.jpg";

const ReferralCode = () => {
  const [referralCode, setreferralCode] = useState("");
  const [referralTaken, setreferralTaken] = useState("");
  const [emailRefer, setEmailRefer] = useState("");

  const fetch = async () => {
    const response = await fetchReferralCode();
    setreferralCode(response.data);
    setreferralTaken(response.referralTaken);
  };

  useEffect(() => {
    fetch();
  }, []);

  const copyToClipboard = async (code) => {
    await navigator.clipboard.writeText(code.referral_code);
    ToastSuccessNotifications("Copied");
  };

  const onChangeEmailRefer = (e) => {
    console.log('working',e.target.value)
    setEmailRefer(e.target.value);
  };

  const sendReferral = async () => {
    console.log('running')
    const send = await sendReferralEmail(emailRefer);
    setEmailRefer("");
    if (send.success == true) {
      ToastSuccessNotifications(send.msg);
    } else {
      ToastErrorNotifications("Error Occured,Failed");
    }
  };

  return (
    <div className="w-full">
      <div className="mx-5 mt-8 mb-3">
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg ">
          <div className="flex justify-center">
            <div className="card mb-16" style={{ width: "500px" }}>
              <h5 className="my-5 text-success text-center font-bold text-lg">
                Refer your Friends to earn rewards
              </h5>
              <div className="">
                <img className="object-contain   " src={referralImage} />
              </div>
              <div className="mx-2  alert flex items-center justify-between rounded-lg bg-success px-4 py-3 text-white dark:bg-accent sm:px-5">
                <p
                  className="text-center  font-bold text-lg"
                  style={{ letterSpacing: "3px" }}
                >
                  {referralCode.referral_code}
                </p>
                <button
                  onClick={() => {
                    copyToClipboard(referralCode);
                  }}
                  className="btn h-6 shrink-0 rounded bg-white/20 px-2 text-xs text-white active:bg-white/25"
                >
                  Copy
                </button>
              </div>

              

              <div className="mt-3 mx-2 alert flex items-center justify-between rounded-lg bg-success px-4 py-3 text-white dark:bg-accent sm:px-5">
                <p
                  className="text-center  font-bold text-lg"
                  style={{ letterSpacing: "2px" }}
                >
                  {referralTaken} User have accepted your referral
                </p>
              </div>



              <div className="flex my-2">
                <input
                  class="form-input mx-2 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-success dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                  placeholder="Enter email to refer"
                  type="text"
                  value={emailRefer}
                  onChange={(e) => {
                    onChangeEmailRefer(e);
                  }}
                />
                <button
                  onClick={() => {
                    sendReferral();
                  }}
                  className="btn mx-2 shrink-0 rounded bg-success px-2 text-xs text-white active:bg-white/25"
                >
                  Send 
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralCode;
