import React from 'react';
import { Helmet } from "react-helmet";
import Navbar from './Component/Navbar';
const Loans = () => {
    return (
        <>
        {/* Mirrored from templates.envytheme.com/finix/default/loans.html by HTTrack Website Copier/3.x [XR&CO'2014], Sun, 26 Feb 2023 16:40:43 GMT */}
        {/* Required meta tags */}
        <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        {/* Bootstrap CSS */} 
        <link rel="stylesheet" href="assets/css/bootstrap.min.css" />
        {/* Animate CSS */} 
        <link rel="stylesheet" href="assets/css/animate.min.css" />
        {/* Meanmenu CSS */}
        <link rel="stylesheet" href="assets/css/meanmenu.css" />
        {/* Boxicons CSS */}
        <link rel="stylesheet" href="assets/css/boxicons.min.css" />
        {/* Flaticon CSS */}
        <link rel="stylesheet" href="assets/css/flaticon.css" />
        {/* Carousel CSS */}
        <link rel="stylesheet" href="assets/css/owl.carousel.min.css" />
        {/* Carousel Default CSS */}
        <link rel="stylesheet" href="assets/css/owl.theme.default.min.css" />
        {/* Magnific Popup CSS */}
        <link rel="stylesheet" href="assets/css/magnific-popup.min.css" />
        {/* Nice Select CSS */}
        <link rel="stylesheet" href="assets/css/nice-select.min.css" />
        {/* Odometer CSS */}
        <link rel="stylesheet" href="assets/css/odometer.min.css" />
        {/* Style CSS */}
        <link rel="stylesheet" href="assets/css/style.css" />
        {/* Dark CSS */}
        <link rel="stylesheet" href="assets/css/dark.css" />
        {/* Responsive CSS */}
        <link rel="stylesheet" href="assets/css/responsive.css" />
        <title>Finix - Loans &amp; Funding HTML Template</title>
        <link rel="icon" type="image/png" href="assets/img/favicon.png" />
        </Helmet>
        <Navbar />
        {/* Start Navbar Area */}
       
        {/* End Navbar Area */}
        {/* Start Page Title Area */}
        <div className="page-title-area item-bg-7">
          <div className="d-table">
            <div className="d-table-cell">
              <div className="container">
                <div className="page-title-content">
                  <h2>Loans</h2>
                  <ul>
                    <li>
                      <a href="index.html">Home</a>
                    </li>
                    <li>Loans</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Title Area */}
        {/* Start Loan Area */}
        <section className="loan-area ptb-100">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="loan-image">
                  <img src="assets/img/loan.png" alt="image" />
                  <div className="loan-shape">
                    <div className="text">
                      <img src="assets/img/finix.png" alt="image" />
                      <span>We believe in those made to do more</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="loan-content">
                  <h3>What is a finix loan?</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                    ipsum suspendisse ultrices gravida. Quis ipsum suspendisse
                    ultrices gravida
                  </p>
                  <ul className="list">
                    <li>Unsecured loans of between $500 - $5,000</li>
                    <li>Borrow over 1-5 years at a fixed interest rate of 6%</li>
                    <li>FREE mentoring for the first year of the loan</li>
                    <li>No minimum trading requirement.</li>
                  </ul>
                  <h4>Resources to help you with your Loan</h4>
                  <ul className="loan-list">
                    <li>
                      <i className="flaticon-check" />
                      Business Plan
                    </li>
                    <li>
                      <i className="flaticon-check" />
                      Budget Planner
                    </li>
                    <li>
                      <i className="flaticon-check" />
                      Cashflow Forecast
                    </li>
                  </ul>
                  <div className="loan-btn">
                    <a href="apply-now.html" className="default-btn">
                      Apply now
                      <span />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Loan Area */}
        {/* Start Overview Area */}
        <section className="overview-area pt-100 pb-70">
          <div className="container">
            <div className="section-title">
              <span>What’s the process?</span>
              <h2>The Loans have helped us move our business forward</h2>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="overview-item">
                  <div className="number">
                    <span>Apply</span>
                    <strong>1</strong>
                  </div>
                  <h3>
                    Easily apply in <br /> minutes
                  </h3>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="overview-item">
                  <div className="number">
                    <span>Process</span>
                    <strong>2</strong>
                  </div>
                  <h3>
                    Clear and transparent <br /> process
                  </h3>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="overview-item">
                  <div className="number">
                    <span>Support</span>
                    <strong>3</strong>
                  </div>
                  <h3>
                    Support from real <br /> people
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="overview-shape">
            <div className="shape">
              <img src="assets/img/works-shape.png" alt="image" />
            </div>
            <div className="shape2">
              <img src="assets/img/works-shape2.png" alt="image" />
            </div>
          </div>
        </section>
        {/* End Overview Area */}
        {/* Start Deserve Area */}
        <section className="deserve-area ptb-100">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="deserve-item">
                  <h3>How do I apply for a loan</h3>
                  <div className="deserve-content">
                    <span>1</span>
                    <h4>Apply in 10 minutes</h4>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                      eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </p>
                  </div>
                  <div className="deserve-content">
                    <span>2</span>
                    <h4>Hear from us in 1 hour</h4>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                      eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </p>
                  </div>
                  <div className="deserve-content">
                    <span>3</span>
                    <h4>A decision in 24 hours</h4>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                      eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </p>
                  </div>
                  <div className="deserve-content">
                    <span>4</span>
                    <h4>Your loan is funded</h4>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                      eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </p>
                  </div>
                  <div className="deserve-btn">
                    <a href="apply-now.html" className="default-btn">
                      Apply now
                      <span />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="default-image">
                  <img src="assets/img/loan2.png" alt="image" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Deserve Area */}
        {/* Start Check Area */}
        <section className="check-area ptb-100">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7">
                <div className="check-image">
                  <img src="assets/img/check.png" alt="image" />
                </div>
              </div>
              <div className="col-lg-5">
                <div className="check-content">
                  <h3>Want to check your eligibility before you apply?</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                    ipsum suspendisse ultrices gravida.
                  </p>
                </div>
                <div className="check-text">
                  <h3>Finix payment flexibility for your loan</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                    ipsum suspendisse ultrices gravida.
                  </p>
                  <ul className="list">
                    <li>Fill Loan Application Form</li>
                    <li>Choose your preferred monthly payment</li>
                    <li>Increase your payment amount anytime</li>
                    <li>No fees, hidden or otherwise</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Check Area */}
        {/* Start Inquiry Area */}
        <section className="inquiry-area ptb-100">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="inquiry-content">
                  <h3>Start-up loan Inquiry</h3>
                  <p>
                    Please complete the short form below with your details and one of
                    our advisors will be touch shortly
                  </p>
                </div>
                <form className="inquiry-form">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Your Name*</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Phone Number*</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Email Address</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Postcode*</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Date of Birth</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Address</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>City</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>How long have you been trading?</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                  </div>
                  <div className="inquiry-btn">
                    <button type="submit" className="default-btn">
                      Submit
                      <span />
                    </button>
                  </div>
                </form>
              </div>
              <div className="col-lg-6">
                <div className="rate-form">
                  <div className="rate-content">
                    <span>Calculate your rate</span>
                    <h3>How much do you need?</h3>
                  </div>
                  <div className="form">
                    <div className="form-group">
                      <label>Type The Money</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="$1000"
                      />
                    </div>
                    <div className="form-group">
                      <label>Select The Month/Years</label>
                      <div className="select-box">
                        <select className="form-control">
                          <option value={5}>1 month</option>
                          <option value={1}>2 month</option>
                          <option value={2}>3 month</option>
                          <option value={0}>4 month</option>
                          <option value={3}>6 month</option>
                          <option value={3}>1 Year</option>
                          <option value={0}>2 Years</option>
                          <option value={3}>3 Years</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Borrowing</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="$10000"
                      />
                    </div>
                    <div className="form-group">
                      <label>Term of use</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="6 month"
                      />
                    </div>
                    <div className="form-group">
                      <label>The total you will pay</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="$11200"
                      />
                    </div>
                    <div className="rate-btn">
                      <button type="submit" className="default-btn">
                        Apply for this loan
                        <span />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Inquiry Area */}
        {/* Start Footer Area */}
        <section className="footer-area pt-100 pb-70">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <div className="single-footer-widget">
                  <div className="logo">
                    <a href="#">
                      <img src="assets/img/logo2.png" alt="image" />
                    </a>
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet, consec tetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  </p>
                  <ul className="social">
                    <li>
                      <b>Follow us:</b>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="flaticon-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="flaticon-instagram" />
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="flaticon-facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="flaticon-linkedin" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="single-footer-widget">
                  <h3>Quick Links</h3>
                  <ul className="quick-links">
                    <li>
                      <a href="about.html">About</a>
                    </li>
                    <li>
                      <a href="#">Our Performance</a>
                    </li>
                    <li>
                      <a href="faq.html">Help (FAQ)</a>
                    </li>
                    <li>
                      <a href="news.html">Blog</a>
                    </li>
                    <li>
                      <a href="contact.html">Contact</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="single-footer-widget">
                  <h3>Other Resources</h3>
                  <ul className="quick-links">
                    <li>
                      <a href="#">Support</a>
                    </li>
                    <li>
                      <a href="privacy-policy.html">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="terms-condition.html">Terms of Service</a>
                    </li>
                    <li>
                      <a href="#">Business Loans</a>
                    </li>
                    <li>
                      <a href="#">Loan Services</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="single-footer-widget">
                  <h3>Contact Us</h3>
                  <div className="info-contact">
                    <i className="flaticon-pin" />
                    <span>6890 Blvd, The Bronx, NY 1058 New York, USA</span>
                  </div>
                  <div className="info-contact">
                    <i className="flaticon-mail" />
                    <span>
                      <a href="https://templates.envytheme.com/cdn-cgi/l/email-protection#254d4049494a65434c4b4c5d0b464a48">
                        <span
                          className="__cf_email__"
                          data-cfemail="264e434a4a4966404f484f5e0845494b"
                        >
                          [email&nbsp;protected]
                        </span>
                      </a>
                    </span>
                    <span>
                      <a href="#">skype: example</a>
                    </span>
                  </div>
                  <div className="info-contact">
                    <i className="flaticon-telephone" />
                    <span>
                      <a href="tel:1514312-6688">+1 (514) 312-5678</a>
                    </span>
                    <span>
                      <a href="tel:1514312-6688">+1 (514) 312-6688</a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Footer Area */}
        {/* Start Copy Right Area */}
        <div className="copy-right-area">
          <div className="container">
            <div className="copy-right-content">
              <p>
                Copyright @ Finix. Designed By
                <a href="https://envytheme.com/" target="_blank">
                  EnvyTheme
                </a>
              </p>
            </div>
          </div>
        </div>
        {/* End Copy Right Area */}
        {/* Start Go Top Area */}
        <div className="go-top">
          <i className="bx bx-chevron-up" />
        </div>
        {/* End Go Top Area */}
        {/* Dark version */}
        <div className="dark-version">
          <label id="switch" className="switch">
            <input type="checkbox" id="slider" />
            <span className="slider round" />
          </label>
        </div>
        {/* Dark version */}
        {/* Jquery Slim JS */}
        {/* Popper JS */}
        {/* Bootstrap JS */}
        {/* Meanmenu JS */}
        {/* Carousel JS */}
        {/* Nice Select JS */}
        {/* Magnific Popup JS */}
        {/* Odometer JS */}
        {/* Appear JS */}
        {/* Form Ajaxchimp JS */}
        {/* Form Validator JS */}
        {/* Contact JS */}
        {/* Wow JS */}
        {/* Custom JS */}
        {/* Mirrored from templates.envytheme.com/finix/default/loans.html by HTTrack Website Copier/3.x [XR&CO'2014], Sun, 26 Feb 2023 16:40:45 GMT */}
      </>
      
    );
};

export default Loans;