import {
  Sidebar,
  SubMenu,
  Menu,
  MenuItem,
  useProSidebar,
} from "react-pro-sidebar";
import { Outlet, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import {
  faUser,
  faChartPie,
  faRightFromBracket
} from "@fortawesome/free-solid-svg-icons";
import {
  faTabletScreenButton,
  faEnvelope,
  faSms,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useState } from "react";

const DsaSidebar = () => {
  const location = useLocation();
  return (
    <Sidebar defaultCollapsed className="bg-white h-screen">
      <Menu renderExpandIcon={({ open }) => <span>{open ? "-" : "+"}</span>}>
        <MenuItem
          className={`font-medium ${
            window.location.pathname === "/dsahome" ? " text-primary " : ""
          }`}
          component={<Link to="/dsahome" />}
          title="Dashboard"
          icon={
            <FontAwesomeIcon
              className={` ${
                window.location.pathname === "/dsahome"
                  ? "bg-zinc-300 px-3 py-3 rounded-lg text-primary text-xl"
                  : ""
              }`}
              icon={faChartPie}
            />
          }
        >
          {" "}
          Dashboard
        </MenuItem>

        <MenuItem
          className={`font-medium ${
            window.location.pathname === "/dsa_user" ? " text-primary " : ""
          }`}
          component={<Link to="dsa_user" />}
          title="users"
          icon={
            <FontAwesomeIcon
              className={` ${
                window.location.pathname === "/dsa_user"
                  ? "bg-zinc-300 px-3 py-3 rounded-lg text-primary text-xl"
                  : ""
              }`}
              icon={faUser}
            />
          }
        >
          Users
        </MenuItem>

        <MenuItem
          className={`font-medium ${
            window.location.pathname === "/logout" ? " text-primary " : ""
          }`}
          component={<Link to="logout" />}
          title="Logout"
          icon={
            <FontAwesomeIcon
              className={` ${
                window.location.pathname === "/logout"
                  ? "bg-zinc-300 px-3 py-3 rounded-lg text-primary text-xl"
                  : ""
              }`}
              icon={faRightFromBracket}
            />
          }
        >
          Log Out
        </MenuItem>
      </Menu>
    </Sidebar>
  );
};

export default DsaSidebar;
