import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  ToastSuccessNotifications,
  ToastErrorNotifications,
} from "../Notifications/ToastNotifications";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useNavigate } from "react-router-dom";
import { getSingleFiles, getLoanStatus, deleteDoc } from "../Data/apis";
import Modal from "react-bootstrap/Modal";
import { faTrash, faExpand } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Home = () => {
  const host = "http://localhost:5000";
  const [authUser, setauthUser] = useState(localStorage.getItem("token"));

  const [show, setShow] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [modalImage, setModalImage] = useState("");
  const [userFiles, setUserFiles] = useState("");
  const [loanStatus, setLoanStatus] = useState("");

  const setModal = (path) => {
    setModalImage(path);
    setShow(true);
  };

  const setDeleteModal = (id) => {
    setDeleteId(id);
    setDeleteShow(true);
  };

  async function getUserFiles() {
    const response = await getSingleFiles();
    console.log(response);
    setUserFiles(response.data);
  }

  async function FetchLoanStatus() {
    const response = await getLoanStatus();
    console.log(response, "loan");
    setLoanStatus(response.data);
  }

  useEffect(() => {
    getUserFiles();
    FetchLoanStatus();
  }, []);

  // delete function
  const deleteDocuments = async (docid) => {
    //api call
    const deleteDocument = await deleteDoc(docid);
    setDeleteShow(false)
    if (deleteDocument.success == true) {
      ToastSuccessNotifications(deleteDocument.message);
    } else {
      ToastErrorNotifications("Error Occured, Try again");
    }
    
    getUserFiles();
  };

  return (
    <div>
      <div className="pb-3"></div>

      <main className=" w-full px-[var(--margin-x)] pb-8">
        <div className="flex justify-center">
          <div className="card py-5" style={{ width: "1300px" }}>
            <div className="flex justify-center my-2">
              <h5 className="text-xl">Loan Status</h5>
            </div>
            <div className="flex justify-center">
              <div className=" text-center" style={{ width: "100px" }}>
                <CircularProgressbar value={70} text={"70%"} strokeWidth={5} />
              </div>
            </div>
            <div className="flex justify-center my-2">
              <h5 className="text-xl">{loanStatus.loan_status}</h5>
            </div>
            <div className="flex justify-center my-2">
              <h5 className="text-5xl">₹420</h5>
            </div>
            <div className="flex justify-center my-2">
              <h5 className="text-sm">{loanStatus.note_to_user}</h5>
            </div>
          </div>
        </div>

        <h5 className="text-xl text-center mt-8">Uploaded Documents</h5>
        <div className="flex justify-center mt-8 mb-3">
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg ">
            <div
              className="is-scrollbar-hidden min-w-full overflow-x-auto"
              style={{ width: "1300px" }}
            >
              <table className="is-hoverable w-full text-left">
                <thead>
                  <tr>
                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                      #
                    </th>
                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                      File
                    </th>
                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                      Document
                    </th>
                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                      Size
                    </th>
                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                      Created On
                    </th>
                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                      FileName
                    </th>
                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                      status
                    </th>
                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {userFiles.length > 0 &&
                    userFiles.map((item, index) => (
                      <tr
                        key={item._id}
                        className="border border-transparent border-b-slate-200 dark:border-b-navy-500"
                      >
                        <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                          {index + 1}
                        </td>
                        <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                          <button
                            className="mx-3 btn rounded-full bg-success font-medium text-white hover:bg-success-focus focus:bg-success-focus active:bg-success-focus/90"
                            onClick={() => setModal(`${host}/${item.filePath}`)}
                          >
                            <FontAwesomeIcon icon={faExpand} />
                          </button>
                        </td>
                        <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                          {item.title}
                        </td>
                        <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                          {item.fileSize}
                        </td>
                        <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                          {item.createdAt}
                        </td>
                        <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                          {item.fileName}
                        </td>
                        <td className="whitespace-nowrap px-4 py-3 sm:px-5 text-yellow-600">
                          {item.status}
                        </td>
                        <td className="whitespace-nowrap px-4 py-3 sm:px-5 text-yellow-600">
                          <button
                            className="mx-3 btn rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90"
                            onClick={() => setDeleteModal(item._id)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>

      <Modal
        show={show}
        size="lg"
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Full Image
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="flex justify-center">
            <iframe
              src={modalImage}
              title="png"
              className="frame"
              height="800px"
              width="1200px"
            ></iframe>
          </div>
        </Modal.Body>
      </Modal>

      {/* //delete modal */}
      <Modal
        show={deleteShow}
        onHide={() => setDeleteShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            <h5 className="text-sm font-semibold">
              {" "}
              Are you sure you want to delete ?
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="flex justify-center">
            <button
              onClick={() => deleteDocuments(deleteId)}
              class="btn mx-2 bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
            >
              Yes
            </button>
            <button
              onClick={() => setDeleteShow(false)}
              class="btn mx-2 bg-slate-150 font-medium text-slate-800 hover:bg-slate-200 focus:bg-slate-200 active:bg-slate-200/80 dark:bg-navy-500 dark:text-navy-50 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Home;
