import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Navigate } from "react-router-dom";
import Signup from "./Pages/signup";
import Login from "./Pages/Login";
import Logout from "./Pages/Logout";
import { Provider } from "react-redux";
import store from "./store/store";
import { ToastContainer, toast } from "react-toastify";
import Home from "./Pages/Home";
import PrivateComponent from "./AuthCheck";
import React from "react";
import Activationform from "./Pages/Activationform";
import { ProSidebarProvider } from "react-pro-sidebar";
import Layout from "./Components/sidebar/Layout";
import Documents from "./Pages/User/Documents";
import UserProfile from "./Pages/User/UserProfile";
import ReferralCode from "./Pages/User/ReferralCode";
import LoanStatus from "./Pages/LoanStatus";
import AdminLogin from "./Pages/AdminLogin";
import DsaLogin from "./Pages/DsaLogin";
import DsaHome from "./Pages/Dsa/DsaHome";
import DsaUser from "./Pages/Dsa/DsaUser";
import DsaUserFiles from "./Pages/Dsa/DsaUserFiles";
import DsaLayout from "./Components/DsaSidebar/Layout";
import DsaComponent from "./DsaAuthCheck";
import AdminHome from "./Pages/Admin/AdminHome";
import AdminComponent from "./AdminAuthCheck";
import AdminLayout from "./Components/AdminSidebar/Layout";
import AllUsers from "./Pages/Admin/AllUsers";
import AllDsa from "./Pages/Admin/AllDsa";
import User from "./Pages/Admin/User";
import Test from "./Pages/Test";
import Users from "./Pages/Admin/Users";
import WebsiteHome from "./FrontWebsite/WebsiteHome";
import AboutUs from "./FrontWebsite/AboutUs";
import TermsAndConditions from "./FrontWebsite/TermsAndConditions";
import PrivacyPolicy from "./FrontWebsite/PrivacyPolicy";
import Contact from "./FrontWebsite/Contact";
import Loans from "./FrontWebsite/Loans";



function App() {
  return (
    <div>
      <ToastContainer />
      <ProSidebarProvider>
        <BrowserRouter>
          <Provider store={store}>
            <Routes>
            <Route path="/test" element={<Test />} />
            <Route path="/studentloanhome" element={<WebsiteHome />} />
            <Route path="/Aboutus" element={<AboutUs />} />
            <Route path="/Termsandconditions" element={<TermsAndConditions />} />
            <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/Contact" element={<Contact />} />
            <Route path="/Loans" element={<Loans />} />

              {/* auth routes */}
              <Route element={<PrivateComponent children={undefined} />}>
                <Route element={<Layout />}>
                  <Route path="/home" element={<Home />} />
                  <Route path="/student_documents" element={<Documents />} />
                  <Route path="/user_profile" element={<UserProfile />} />
                  <Route path="/loan_status" element={<LoanStatus />} />
                  <Route path="/referral_code" element={<ReferralCode />} />
                </Route>
              </Route>
              {/* end auth routes */}

              {/* admin auth routes */}
              <Route path="/admin" element={<AdminLogin />} />

              <Route element={<AdminComponent children={undefined} />}>
              <Route element={<AdminLayout />}>
                <Route path="/adminhome" element={<AdminHome />} />
                <Route path="/users" element={<AllUsers />} />
                <Route path="/all_registered_users" element={<Users />} />
                <Route path="/user/:id" element={<User />} />
                <Route path="/all_dsa" element={<AllDsa />} />
                </Route>
              </Route>
              {/* end admin auth routes */}

               {/* admin auth routes */}
               <Route path="/dsa" element={<DsaLogin />} />
               <Route element={<DsaComponent children={undefined} />}>
               <Route element={<DsaLayout />}>
               <Route path="/dsahome" element={<DsaHome />} />
               <Route path="/dsa_user" element={<DsaUser />} />
               <Route path="/dsa_user/:id" element={<DsaUserFiles />} />
               </Route>
               </Route>
               {/* end dsa auth routes */}

              <Route path="/signup" element={<Signup />} />
              <Route path="/login" element={<Login />} />
              <Route path="/activation_form" element={<Activationform />} />
              <Route path="/logout" element={<Logout />} />

              <Route path="*" element={<Navigate to="/login" replace />} />
            </Routes>
          </Provider>
        </BrowserRouter>
      </ProSidebarProvider>
    </div>
  );
}

export default App;
