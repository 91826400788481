import Dropdown from "react-bootstrap/Dropdown";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useProSidebar } from "react-pro-sidebar";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import React,{useState,useEffect} from "react";
function AdminTopbar() {
  const location = useLocation();
  
  const { collapseSidebar } = useProSidebar();

  return (
    <div className="flex border-b-2 border-slate-200 mb-3 pb-3 bg-white">
      <button
        className="rounded-lg  px-5 mt-2  ml-3"
        onClick={() => collapseSidebar()}
      >
        <FontAwesomeIcon icon={faBars} />
      </button>
     
      <div className="w-full flex justify-end items-center mt-2 ">
        <div className="mr-3">
          <Dropdown>
            <Dropdown.Toggle
              className="px-2 py-3 "
              style={{ backgroundColor: "white", textAlign: "center" }}
              variant="light"
              id="dropdown-basic"
            >
              <FontAwesomeIcon className="" icon={faBell} color={"blue"} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                href="#/action-1"
                style={{ borderBottom: "1px solid rgb(226 232 240)" }}
              >
                {" "}
                <p>
                  Welcome to axon aio.Thank you for registering with us.
                </p>{" "}
              </Dropdown.Item>

              <Dropdown.Item
                href="#/action-1"
                style={{ borderBottom: "1px solid rgb(226 232 240)" }}
              >
                {" "}
                <p> Your account is activated.</p>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="mr-5">
          <Dropdown>
            <Dropdown.Toggle
              className="px-1"
              style={{ backgroundColor: "white" }}
              variant="light"
              id="dropdown-basic"
            >
              <img
                src="https://cdn4.iconfinder.com/data/icons/small-n-flat/24/user-512.png"
                alt=""
                width="20"
                height="60"
              />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item>UID:465132132132</Dropdown.Item>
              <Dropdown.Item href="#/action-1">Profile</Dropdown.Item>
              <Dropdown.Item href="#/action-2">Support</Dropdown.Item>
              <Dropdown.Item href="#/action-3">Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
}

export default AdminTopbar;
