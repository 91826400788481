import {configureStore} from '@reduxjs/toolkit';
import  authSlice  from './authSlice';
import domainSlice from './domainSlice';
import userDetailSlice from './userDetailSlice';


const store = configureStore({
    reducer:{
        user: authSlice,
        domain:domainSlice,
        userdetails:userDetailSlice
    }
 
})

export default store;