import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import {
  fetchUserInfo,
  getUserFiles,
  updateFileStatus,
  getUserNotifications,
  sendNotification,
  fetchImageAdmin
} from "../../Data/adminapis";
import Modal from "react-bootstrap/Modal";
import {
  faTrash,
  faExpand,
  faEye,
  faCheck,
  faBan,
  faArrowsRotate,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import {
  ToastErrorNotifications,
  ToastSuccessNotifications,
} from "../../Notifications/ToastNotifications";
import ProfileEdit from "../../Components/Admin/ProfileEdit";

const User = () => {
  const host = "http://localhost:5000";
  const [show, setShow] = useState(false);
  const [notificationShow, setNotificationShow] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const [notificationError, setNotificationError] = useState("");
  const { id } = useParams();
  const [userinfo, setUserinfo] = useState("");
  const [userid, setUserid] = useState("");
  const [userFiles, setUserFiles] = useState("");
  const [userNotifications, setUserNotifications] = useState("");
  const [notification, setNotification] = useState("");
  const [userEditModal, setuserEditModal] = useState(false);
  const [profileImage, setprofileImage] = useState("");

   const setModal = async (path) => {
    const result = await fetchImageAdmin(`/${path}`)
    setModalImage(result);
    setShow(true);
  };

  const fetchUser = async () => {
    const response = await fetchUserInfo(id);
    setUserinfo(response.data);
    setUserid(response.data.user._id);
    const filesresponse = await getUserFiles(response.data.user._id);
    
    const profileImage = filesresponse.data.filter((item)=>{ return item.title == "Color Photograph"})
    console.log(profileImage,'profile')
    setprofileImage(profileImage[0])
    setUserFiles(filesresponse.data);
    const notificationresponse = await getUserNotifications(
      response.data.user._id
    );
    setUserNotifications(notificationresponse.data);
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const FileStatusUpdate = async (id, status) => {
    console.log(id, status);
    const response = await updateFileStatus(id, status);
    if (response.success == true) {
      ToastSuccessNotifications(response.message);
      fetchUser();
    } else {
      ToastErrorNotifications(response.message);
    }
  };

  const onChangeNotification = (e) => {
    setNotification(e.target.value);
  };

  const submitNotification = async () => {
    if (notification == "") {
      setNotificationError("This Field cannot be blank.");
    } else {
      const response = await sendNotification(userid, notification);
      setNotificationShow(false);
      const notificationresponse = await getUserNotifications(userid);
      setUserNotifications(notificationresponse.data);
      setNotificationError("");
      setNotification("");
      ToastSuccessNotifications(response.message);
    }
  };

  return (
    <div className="w-full">
      <div className="mx-5 mt-8 mb-3">
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg ">
          <div
            className="is-scrollbar-hidden min-w-full overflow-x-auto"
            style={{ width: "" }}
          >
            {userinfo != "" && (
              <div className="mx-5 flex">
                <div className="card" style={{ width: "" }}>
                  <div className="flex rounded-md bg-slate-200 justify-center">
                    <div className="text-lg">Information</div>
                  </div>

                  <div className="grid grid-cols-4 gap-8">
                    <div>
                      <img
                        src={profileImage != undefined ? `${host}/${profileImage.filePath}` : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"}
                        className="rounded-md my-3 ml-2"
                        alt=""
                       
                        style={{width:'150px',height:'200px'}}
                      />
                    </div>

                    <div>
                      <h6 className="text-sm font-semibold">Name:</h6>
                      <h5 className="text-lg font-semibold">
                        {userinfo.fullname}
                      </h5>
                      <h6 className="text-sm font-semibold">Email:</h6>
                      <h5 className="text-lg font-semibold">
                        {userinfo.email}
                      </h5>
                      <h6 className="text-sm font-semibold">Number:</h6>
                      <h5 className="text-lg font-semibold">
                        {userinfo.number}
                      </h5>
                      <h6 className="text-sm font-semibold">DOB:</h6>
                      <h5 className="text-lg font-semibold">
                        {userinfo.dob}
                      </h5>
                      <h6 className="text-sm font-semibold">Gender:</h6>
                      <h5 className="text-lg font-semibold">
                        {userinfo.gender}
                      </h5>
                    </div>


                    <div>
                      <h6 className="text-sm font-semibold">Occupation:</h6>
                      <h5 className="text-lg font-semibold">
                        {userinfo.occupation}
                      </h5>
                      <h6 className="text-sm font-semibold">District:</h6>
                      <h5 className="text-lg font-semibold">
                        {userinfo.district}
                      </h5>
                      <h6 className="text-sm font-semibold">City:</h6>
                      <h5 className="text-lg font-semibold">
                        {userinfo.city}
                      </h5>
                      <h6 className="text-sm font-semibold">State:</h6>
                      <h5 className="text-lg font-semibold">
                        {userinfo.state}
                      </h5>
                      <h6 className="text-sm font-semibold">Zipcode:</h6>
                      <h5 className="text-lg font-semibold">
                        {userinfo.zipcode}
                      </h5>
                    </div>
                    <div>
                      <button className="mt-3 mx-3 mb-3 btn rounded-full bg-success font-medium text-white hover:bg-success-focus focus:bg-success-focus active:bg-success-focus/90 " onClick={() => setuserEditModal(true)}>Edit Profile</button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <h5 className="text-xl text-center mt-8">Notifications</h5>

            <button
              className="mx-3 mb-3 btn rounded-full bg-success font-medium text-white hover:bg-success-focus focus:bg-success-focus active:bg-success-focus/90"
              onClick={() => setNotificationShow(true)}
            >
              Send Notifications
            </button>
            <div className="mx-5 " style={{ minWidth: "" }}>
              <table className="is-hoverable w-full text-left">
                <thead>
                  <tr>
                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                      #
                    </th>
                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                      Message
                    </th>
                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                      Date
                    </th>
                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                      Sender
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {userNotifications.length > 0 &&
                    userNotifications.map((item, index) => (
                      <tr
                        key={item._id}
                        className="border border-transparent border-b-slate-200 dark:border-b-navy-500"
                      >
                        <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                          {index + 1}
                        </td>

                        <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                          {item.message}
                        </td>

                        <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                          {moment(item.date).format("D-M-YYYY hh:mm:ss")}
                        </td>
                        <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                          {item.sender_user_id.name}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>

            <h5 className="text-xl text-center my-8">Uploaded Documents</h5>
            <div className="mx-5 mb-8" style={{ minWidth: "" }}>
              <table className="is-hoverable w-full text-left">
                <thead>
                  <tr>
                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                      #
                    </th>
                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                      File
                    </th>
                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                      Document
                    </th>
                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                      Size
                    </th>
                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                      Created On
                    </th>
                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                      FileName
                    </th>
                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                      Status
                    </th>
                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {userFiles.length > 0 &&
                    userFiles.map((item, index) => (
                      <tr
                        key={item._id}
                        className="border border-transparent border-b-slate-200 dark:border-b-navy-500"
                      >
                        <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                          {index + 1}
                        </td>
                        <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                          <button
                            className="mx-3 btn rounded-full bg-success font-medium text-white hover:bg-success-focus focus:bg-success-focus active:bg-success-focus/90"
                            onClick={() => setModal(item.filePath)}
                          >
                            <FontAwesomeIcon icon={faExpand} />
                          </button>
                        </td>
                        <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                          {item.title}
                        </td>
                        <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                          {item.fileSize}
                        </td>
                        <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                          {moment(item.createdAt).format("D-M-YYYY")}
                        </td>
                        <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                          {item.fileName}
                        </td>
                        <td className="whitespace-nowrap px-4 py-3 sm:px-5 ">
                          {item.status == "Approved" ? (
                            <span className="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded ">
                              {item.status}
                            </span>
                          ) : item.status == "Declined" ? (
                            <span className="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded ">
                              {item.status}
                            </span>
                          ) : (
                            <span className="bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded ">
                              {item.status}
                            </span>
                          )}
                        </td>

                        <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                          <button
                            onClick={() => {
                              FileStatusUpdate(item._id, "Approved");
                            }}
                          >
                            <FontAwesomeIcon
                              className="mx-2 rounded-md border border-red-400 border-dotted px-2 py-1 text-lg text-blue-600"
                              icon={faCheck}
                            />
                          </button>

                          <button
                            onClick={() => {
                              FileStatusUpdate(item._id, "Declined");
                            }}
                          >
                            <FontAwesomeIcon
                              className="mx-2 rounded-md border border-red-400 border-dotted px-2 py-1 text-lg text-red-600"
                              icon={faBan}
                            />
                          </button>

                          <button
                            onClick={() => {
                              FileStatusUpdate(item._id, "Pending");
                            }}
                          >
                            <FontAwesomeIcon
                              className="mx-2 rounded-md border border-red-400 border-dotted px-2 py-1 text-lg text-yellow-600"
                              icon={faArrowsRotate}
                            />
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>

            <Modal
              show={show}
              size="lg"
              onHide={() => setShow(false)}
              dialogClassName="modal-90w"
              aria-labelledby="example-custom-modal-styling-title"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                  Full Image
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="flex justify-center">
                  <iframe
                    src={modalImage}
                    title="png"
                    className="frame"
                    height="800px"
                    width="1000px"
                  ></iframe>
                </div>
              </Modal.Body>
            </Modal>

            {/* notification modal */}

            <Modal
              show={notificationShow}
              onHide={() => setNotificationShow(false)}
              aria-labelledby="example-custom-modal-styling-title"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                  Send Notification
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="flex justify-center">
                  <div style={{ width: "100%" }}>
                    <label htmlFor="joining_year">Notification:</label>
                    <input
                      type="text"
                      value={notification}
                      className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                      onChange={(e) => {
                        onChangeNotification(e);
                      }}
                    />
                    {notificationError != "" && (
                      <div className="text-red-600">{notificationError}</div>
                    )}
                  </div>
                </div>
                <div className="flex justify-center mt-3">
                  <button
                    type="submit"
                    className="btn  mx-3 border border-primary font-medium text-primary hover:bg-primary hover:text-white focus:bg-primary focus:text-white active:bg-primary/90 dark:border-accent dark:text-accent-light dark:hover:bg-accent dark:hover:text-white dark:focus:bg-accent dark:focus:text-white dark:active:bg-accent/90"
                    onClick={() => {
                      submitNotification();
                    }}
                  >
                    Send
                  </button>
                </div>
              </Modal.Body>
            </Modal>

            {/* usereditmodal */}
            <Modal
              show={userEditModal}
              size="lg"
              onHide={() => setuserEditModal(false)}
              dialogClassName="modal-90w"
              aria-labelledby="example-custom-modal-styling-title"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                 Edit User
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <ProfileEdit prop={userinfo} modalFunction={setuserEditModal} fetchUser={fetchUser} />
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default User;
