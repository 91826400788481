import React, { useState ,useEffect} from "react";
import { Navigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ToastErrorNotifications } from "./Notifications/ToastNotifications";

const PrivateComponent = ({ children }) => {
  let navigate = useNavigate();
  const [jwt, setJwt] = useState(localStorage.getItem("token"));
  const [formFilled, setformFilled] = useState(localStorage.getItem("activation_form"));

 
 console.log(jwt,'jwt','form',formFilled)
 if(jwt && formFilled == 1){
  console.log('outlet')
  return  <Outlet/>;
 }
 else if(jwt && formFilled == 0){
  console.log('activation')
  return <Navigate to="/activation_form" />
 }
 else{
  console.log('login')
  return <Navigate to="/login" />
 }
//   return jwt && formFilled === 1 ? <Outlet/> : jwt && formFilled === 0 ? <Navigate to="/activation_form" /> : <Navigate to="/login" />;
};

export default PrivateComponent;
