import axios from "axios";

const apiUrl = process.env.REACT_APP_BACKEND_URL;

export const singleFileUpload = async (data, options) => {
  try {
    const response = await axios.post(
      apiUrl + "/api/file/singleFile",
      data,
      options
    );
    console.log(response.data);
    const json = await response.data;

    return json;
  } catch (error) {
    throw error;
  }
};

export const getSingleFiles = async (data) => {
  //api call
  const url = `${apiUrl}/api/file/getSingleFiles`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "auth-token": localStorage.getItem("token"),
    },
  });
  const json = await response.json();

  return json;
};

export const getLoanStatus = async () => {
  //api call
  const url = `${apiUrl}/api/loan/fetch_loan_status`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "auth-token": localStorage.getItem("token"),
    },
  });
  const json = await response.json();

  return json;
};

export const deleteDoc = async (docid) => {
  const url = `${apiUrl}/api/file/deleteFile/${docid}`;
  const response = await fetch(url, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "auth-token": localStorage.getItem("token"),
    },
  });
  const json = await response.json();

  return json;
};

//fetching from details collections
export const fetchUserDetails = async (data) => {
  //api call
  const url = `${apiUrl}/api/details/fetchuserdetail`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "auth-token": localStorage.getItem("token"),
    },
  });
  const json = await response.json();

  return json;
};

export const addDetail = async (data) => {
  //api call
  const url = `${apiUrl}/api/details/adddetail`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": localStorage.getItem("token"),
    },

    body: JSON.stringify({
      gender: data.gender,
      dob: data.dob,
      occupation: data.occupation,
      district: data.district,
      fatheroccupation: data.father_occupation,
      motheroccupation: data.mother_occupation,
      familyincome: data.family_annual_income,
      parentnumber: data.parent_contact_number,
      fullname: data.name,
      fathersname: data.father_name,
      mothersname: data.mother_name,
      email: data.email,
      number: data.mobile,
      course: data.course,
      joiningyear: data.joining_year,
      metric: data.tenth,
      highersecondary: data.twelth,
      passedcourse: data.graduated_course,
      passinggrade: data.graduated_grade,
      address: data.address,
      address2: data.street_name,
      city: data.city,
      state: data.state,
      zipcode: data.zip_code,
    }),
  });
  const json = await response.json();

  return json;
};

export const updateDetail = async (data) => {
  //api call
  const url = `${apiUrl}/api/details/updatedetails`;
  const response = await fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "auth-token": localStorage.getItem("token"),
    },

    body: JSON.stringify({
      gender: data.gender,
      dob: data.dob,
      occupation: data.occupation,
      district: data.district,
      fatheroccupation: data.father_occupation,
      motheroccupation: data.mother_occupation,
      familyincome: data.family_annual_income,
      parentnumber: data.parent_contact_number,
      fullname: data.name,
      fathersname: data.father_name,
      mothersname: data.mother_name,
      email: data.email,
      number: data.mobile,
      course: data.course,
      joiningyear: data.joining_year,
      metric: data.tenth,
      highersecondary: data.twelth,
      passedcourse: data.graduated_course,
      passinggrade: data.graduated_grade,
      address: data.address,
      address2: data.street_name,
      city: data.city,
      state: data.state,
      zipcode: data.zip_code,
    }),
  });
  const json = await response.json();

  return json;
};

export const multipleFilesUpload = async (data, options) => {
  try {
    await axios.post(apiUrl + "/api/file/multipleFiles", data, options);
  } catch (error) {
    throw error;
  }
};

export const getMultipleFiles = async () => {
  try {
    const { data } = await axios.get(apiUrl + "/api/file/getMultipleFiles");
    return data;
  } catch (error) {
    throw error;
  }
};

//fetching user notification
export const fetchUserNotifications = async (data) => {
  //api call
  const url = `${apiUrl}/api/loan/get_notification_to_user`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "auth-token": localStorage.getItem("token"),
    },
  });
  const json = await response.json();

  return json;
};

//fetch referral code
export const fetchReferralCode = async (data) => {
  //api call
  const url = `${apiUrl}/api/auth/getReferralCode`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "auth-token": localStorage.getItem("token"),
    },
  });
  const json = await response.json();

  return json;
};

//get Image
export const fetchImage = async (imageurl) => {
  //api call
  const url = `${apiUrl}/api/auth/get_image`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": localStorage.getItem("token"),
    },
    body: JSON.stringify({
      url: imageurl,
    }),
  });

  const imageBlob = await response.blob();
  const imageObjectURL = URL.createObjectURL(imageBlob);

  return imageObjectURL;
};

//send referral email
export const sendReferralEmail = async (email) => {
  //api call
  const url = `${apiUrl}/api/auth/send_referral_email`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": localStorage.getItem("token"),
    },

    body: JSON.stringify({
      email: email
    }),
  });
  const json = await response.json();

  return json;
};