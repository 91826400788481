import React, { useState, useEffect } from "react";
import { singleFileUpload, getSingleFiles, deleteDoc } from "../Data/apis";
import "./DocUpload.css";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import IframeResizer from "iframe-resizer-react";
import { faTrash, faExpand } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastErrorNotifications,ToastSuccessNotifications } from "../Notifications/ToastNotifications";
import UserProfileEditForm from "./UserProfileEditForm";

const UserProfileEdit = (props) => {
    const [show, setShow] = useState(false);
   
    return (
        <div>
            <button
                        className="mx-3 btn rounded-full bg-success font-medium text-white hover:bg-success-focus focus:bg-success-focus active:bg-success-focus/90"
                          onClick={() => setShow(true)}
                        >
                          Edit
                        </button>
                        <Modal
        show={show}
        size="lg"
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
           Edit User Profile
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="flex justify-center">
           <UserProfileEditForm function={setShow} FetchDetails={props.fetchUserDetailsFunction}/>
          </div>
        </Modal.Body>
      </Modal>
        </div>
    );
};

export default UserProfileEdit;