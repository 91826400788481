import { useNavigate } from "react-router-dom";
import { ToastErrorNotifications } from "../Notifications/ToastNotifications";
const apiUrl = process.env.REACT_APP_BACKEND_URL;




export const getAssignedUsers = async () => {
  
  //api call
  const url = `${apiUrl}/api/dsa/all_assigned_users`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": localStorage.getItem("token"),
    },
  });
  const json = await response.json();

  

  return json;
};

export const getFiles = async (userId) => {
  const url = `${apiUrl}/api/dsa/get_files`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": localStorage.getItem("token"),
    },
    body: JSON.stringify({
      userid: userId,
    }),
  });
  const json = await response.json();

  return json;
};

export const getUserInfo = async (userId) => {
  const url = `${apiUrl}/api/dsa/get_userinfo`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": localStorage.getItem("token"),
    },
    body: JSON.stringify({
      userid: userId,
    }),
  });
  const json = await response.json();

  return json;
};

export const updateFileStatus = async (id, status) => {
  const url = `${apiUrl}/api/dsa/update_file_status/${id}`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": localStorage.getItem("token"),
    },
    body: JSON.stringify({
      status: status,
    }),
  });
  const json = await response.json();

  return json;
};

export const getUserStatus = async (id, status) => {
  const url = `${apiUrl}/api/dsa/get_user`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "auth-token": localStorage.getItem("token"),
    },
  });
  const json = await response.json();

  return json;
};


//get Image
export const fetchImageDsa = async (imageurl) => {
  //api call
  const url = `${apiUrl}/api/dsa/get_image`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": localStorage.getItem("token"),
    },
    body: JSON.stringify({
      url: imageurl
    }),
  });

  const imageBlob = await response.blob();
  const imageObjectURL = URL.createObjectURL(imageBlob);

  return imageObjectURL;
};
