import React, { useState, useEffect } from "react";
import { fetchImage } from "../Data/apis";

const Test = () => {
  const [image, setImage] = useState("");

  const fetch = async () => {
    const getData = await fetchImage(
      "/uploads/2023-02-15T12-41-52.160Z-profile-pic (1).png"
    );
    console.log(getData)
    setImage(getData);
  };

  useEffect(() => {
    fetch();
  }, []);

  return <div>
    <h1>Hello</h1>{image != "" && <img src={image} alt="fdsaf"></img>}</div>;
};

export default Test;
