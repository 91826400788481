import React from "react";
import { Link } from "react-router-dom";
const Navbar = () => {
  return (
    <div className="d-flex justify-content-center mt-2 mb-4">
      <div className="mx-3">
        <Link to="/Aboutus">
          {" "}
          <p style={{ fontWeight: "500", color: "black" }}>About Us</p>{" "}
        </Link>
      </div>
      <div className=" mx-3">
        <Link to="/Termsandconditions">
          {" "}
          <p style={{ fontWeight: "500", color: "black" }}>
            Terms and Conditions
          </p>
        </Link>
      </div>
      <div className=" mx-3">
        <Link to="/PrivacyPolicy">
          {" "}
          <p style={{ fontWeight: "500", color: "black" }}>Privacy Policy</p>
        </Link>
      </div>
      <div className="mx-3">
        <Link to="/Contact">
          {" "}
          <p style={{ fontWeight: "500", color: "black" }}>Contact</p>
        </Link>
      </div>
      <div className="mx-3">
        <Link to="/Loans">
          {" "}
          <p style={{ fontWeight: "500", color: "black" }}>Loans</p>
        </Link>
      </div>
    </div>
  );
};

export default Navbar;
