import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { getUserStatus } from "./Data/dsaapis";
import { ToastErrorNotifications } from "./Notifications/ToastNotifications";

const DsaComponent = ({ children }) => {
  let navigate = useNavigate();
  const [jwt, setJwt] = useState(localStorage.getItem("token"));
  const [user, setUser] = useState(localStorage.getItem("user_dsa"));
  const [status, setStatus] = useState("");

  const fetch = async () => {
    const response = await getUserStatus();
    setStatus(response.data.is_active);
    if (response.data.is_active == 0) {
      localStorage.removeItem("token");
      localStorage.removeItem("user_dsa");
      ToastErrorNotifications("User Has been deactivated.Please Contact Admin");
      navigate("/dsa");
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  if (user == 1 && jwt) {
    return jwt ? <Outlet /> : <Navigate to="/dsahome" />;
  } else {
    console.log("dsa");
    return <Navigate to="/dsa" />;
  }
};

export default DsaComponent;
