import React, { useState, useEffect } from "react";
import {
  singleFileUpload,
  getSingleFiles,
  deleteDoc,
  fetchImage,
} from "../Data/apis";
import "./DocUpload.css";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import IframeResizer from "iframe-resizer-react";
import { faTrash, faExpand } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ToastErrorNotifications,
  ToastSuccessNotifications,
} from "../Notifications/ToastNotifications";

const DocUpload = (props) => {
  const host = "http://localhost:5000";
  const [show, setShow] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const [userid, setUserid] = useState("");

  const [singleFile, setSingleFile] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState("");

  const docsArray = [
    { name: "Colour Photograph of applicant", title: "Color Photograph" },
    {
      name: "Colour Photograph of co-applicant/co-borrower (parents/legal guardian)",
      title: "Colour Photograph of co-applicant/co-borrower",
    },
    { name: "Aadhaar Card", title: "Aadhaar Card" },
    {
      name: "Applicant's PAN Card or undertaking in prescribed format if no PAN Card",
      title: "PAN Card",
    },
    {
      name: "Guardian/co-borrower's PAN card or undertaking in prescribed format",
      title: "Guardian PAN card",
    },
    { name: "Admission Receipt(not mandatory)", title: "Admission Receipt" },
    {
      name: "Prospectus/Certificate (from the competent authority of the institution wherein charges like Admission Fee, Examination Fee, Hostel Charges etc. are mentioned)(not mandatory)",
      title: "Prospectus",
    },
    {
      name: "Age Proof of applicant (PAN/Aadhaar/Class l0 Board Admit Card/Driving Licence)",
      title: "Age Proof",
    },
    {
      name: "Last Qualifying Exam Certificate/Marksheet of applicant",
      title: "Last Exam Marksheet",
    },
    {
      name: "Bank details of applicant : first page of bank passbook containing bank name & branch, account number, IFSC etc.",
      title: "Bank details of applican",
    },
    {
      name: "Bank details of co-borrower: first page of bank passbook containing bank name & branch, account number, IFSC etc.",
      title: "Bank details of co-borrower",
    },
    {
      name: "ITR of last 2years of co- borrower(Non Mandetory)",
      title: "ITR of last 2years",
    },
  ];

  const [docs, setDocs] = useState(docsArray);

  const SingleFileChange = (e) => {
    setSingleFile(e.target.files[0]);
  };

  const setModal = async (path) => {
    const result = await fetchImage(`/${path}`);
    setModalImage(result);
    setShow(true);
  };

  let id;

  async function getUserid() {
    const response = await fetch(`${host}/api/details/fetchdetail`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
    });
    const json = await response.json();
    id = json[0]._id;
    setUserid(json[0]._id);
    getUploadedFiles(json[0]._id);
  }

  async function getUploadedFiles(iduser) {
    const response = await getSingleFiles(iduser);
   
    //adding Image blob to array ..
    for (let i = 0; i < response.data.length; i++) {
      const result = await fetchImage(`/${response.data[i].filePath}`);
      response.data[i]["blob"] = result;
    }

    setUploadedFiles(response.data);
  }

  // delete function
  const deleteDocuments = async (docid) => {
    //api call
    const deleteDocument = await deleteDoc(docid);
    console.log(deleteDocument);
    getUploadedFiles(userid);
  };

  useEffect(() => {
    getUserid();
  }, []);

  function getExtension(filename) {
    return filename.split(".").pop();
  }

  const submitFilesForm = async (values, { resetForm }) => {
    console.log(values,'click upload button');
    let formData = new FormData();

    // Update the formData object
    formData.append("file", values.file);
    formData.append("title", values.title);
    formData.append("id", userid);
    const response = await singleFileUpload(formData);
    console.log(response)
    if(response.success == true ){
      ToastSuccessNotifications(response.message)
      getUploadedFiles()
    }
    else {
      ToastErrorNotifications(response.message)
    }


  };

  return (
    <>
      <h2 className="text-2xl text-center my-2 text-violet-500">
        Upload Documents
      </h2>
      <div className="grid grid-cols-2 gap-4 mx-5">
        {docs.map((doc, index) => (
          <Formik
            enableReinitialize
            initialValues={{
              file: "",
              title: doc.title,
            }}
            key={index + 1}
            validationSchema={Yup.object().shape({
              file: Yup.mixed()
                .required("Please select a file first")
                .test("fileSize", "Image file size is too large", (value) => {
                  if (!value) {
                    return true; // no file provided
                  }
                  return value.size <= 2 * 1024 * 1024; // 2 MB
                })
                .test("fileType", "Invalid file type", (value) => {
                  if (!value) {
                    return true; // no file provided
                  }
                  const allowedFileTypes = [
                    "image/jpeg",
                    "image/jpg",
                    "image/bmp",
                    "image/png",
                    "application/pdf",
                  ];
                  return allowedFileTypes.includes(value.type);
                }),
            })}
            onSubmit={submitFilesForm}
          >
            {(formik) => {
              const { errors, touched, isValid, dirty, setFieldValue } = formik;

              return (
                <div className="card mt-5 rounded-lg p-5 lg:p-7">
                  <Form>
                    <label className="block">
                      <span>
                        {doc.name}
                        <p style={{ display: "inline", color: "red" }}>*</p>
                      </span>
                      <div className="flex ">
                        <input
                          type="file"
                          id="file"
                          onChange={(event) => {
                            setFieldValue("file", event.target.files[0]);
                          }}
                          className="grow form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                          placeholder=""
                        />
                        <Field type="hidden" name="title" value="" />
                        <button
                          type="submit"
                          className="flex-none w-20 mx-5 btn border border-primary font-medium text-primary hover:bg-primary hover:text-white focus:bg-primary focus:text-white active:bg-primary/90 dark:border-accent dark:text-accent-light dark:hover:bg-accent dark:hover:text-white dark:focus:bg-accent dark:focus:text-white dark:active:bg-accent/90"
                        >
                          Upload
                        </button>
                      </div>

                      {errors.file && touched.file && (
                        <span className="text-tiny+ text-error">
                          {errors.file}
                        </span>
                      )}
                    </label>
                  </Form>
                </div>
              );
            }}
          </Formik>
        ))}
      </div>

      <div className="container-fluid mt-5">
        <div className="row">
          <div className="col-12">
            <h4
              className=" font-weight-bold text-center"
              style={{ color: "#7451f8" }}
            >
              Your Documents
            </h4>
            <div className="grid grid-cols-4 gap-4">
              {uploadedFiles.length > 0 &&
                uploadedFiles.map((file) => (
                  <div className="" key={file._id}>
                    <div className="card mb-4 border-0 p-0">
                      <div className="text-center">
                        <h6>{file.title}</h6>
                      </div>
                      {/* {(`${host}/${file.filePath}`)['.type'] === ".pdf" ? ( */}
                      {getExtension(
                        `${host}/${file.filePath}`
                      ).toLowerCase() === "pdf" ? (
                        <div className="flex justify-center">
                          <iframe
                            src={`${host}/${file.filePath}`}
                            title={file.fileName}
                            className="frame"
                            scrolling="no"
                            height="285px"
                            width="285px"
                          ></iframe>
                        </div>
                      ) : (
                        <img
                          src={file.blob}
                          height="285"
                          className="card-img-top img-responsive"
                          style={{ position: "relative" }}
                          alt="pdf"
                        />
                      )}

                      <div className="flex justify-center my-2">
                        <button
                          className="mx-3 btn rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                          onClick={() => deleteDocuments(file._id)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                        <button
                          className="mx-3 btn rounded-full bg-success font-medium text-white hover:bg-success-focus focus:bg-success-focus active:bg-success-focus/90"
                          onClick={() => setModal(file.filePath)}
                        >
                          <FontAwesomeIcon icon={faExpand} />
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>

      <hr style={{ width: "250px", margin: "auto", marginBottom: "20px" }} />

      <Modal
        show={show}
        size="lg"
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Full Image
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="flex justify-center">
            <iframe
              src={modalImage}
              title="png"
              className="frame"
              height="800px"
              width="1200px"
            ></iframe>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DocUpload;
