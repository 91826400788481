import React, { useState } from 'react'
import signupImage from "../Images/signup.svg";
import { Formik, Form, Field } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { ToastErrorNotifications,ToastSuccessNotifications  } from '../Notifications/ToastNotifications';
import { Link } from "react-router-dom";


const Signup = (props) => {
  let navigate = useNavigate();

  const [credentials, setCredentials] = useState({ name: "", email: "", number: "", password: "", cpassword: "" });
  

  const initialValues = {
    name: "",
    email: "",
    number: "",
    password: "",
    cpassword: "",
  };

  const SignInSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    number: Yup.number()
    .required("Mobile is required")
    .test('len', 'Must be exactly 10 characters',function(val){
      
      if(val != undefined){
        if (val.toString().length == 10){
         
          return 1;
        }
        else{
          
          return 0;
          
        }
      }
    
    })
    ,
    email: Yup.string()
      .email("Email is not valid")
      .required("Email is required"),
    password: Yup.string().required("Password is required").min(5, "Password has to be in 5 digits or letters"),
    cpassword: Yup.string().required("Confirm Password is required").min(5, "Password has to be in 5 digits or letters"),
  });

  const handleSubmit = async (values) => {
    if (values.password === values.cpassword) {

      const response = await fetch(`http://localhost:5000/api/auth/newuser`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name:values.name, email:values.email, number:values.number, password:values.password })
      });
      const json = await response.json()

      if (json.success) {
        //redirect
        localStorage.setItem('token', json.authToken);
        navigate("/home");
        ToastSuccessNotifications("Account created succesfully")
      }
      else {
        ToastErrorNotifications("Error Occured , Please Try again")
      }
    }
    else {
      ToastErrorNotifications("Confirm Password is different than the password , Please Try again")
    }
  }

  
  return (
    <div
      id="root"
      className="min-h-100vh flex grow bg-slate-50 dark:bg-navy-900"
    >
      <div className="hidden w-full place-items-center lg:grid">
        <div className="w-full max-w-lg p-6">
          <img className="w-full" src={signupImage} alt="image" />
        </div>
      </div>

      <main className="flex w-full flex-col items-center bg-white dark:bg-navy-700 lg:max-w-md">
        <div className="flex w-full max-w-sm grow flex-col justify-center p-5">
          <div className="text-center">
            
            <div className="">
              <h2 className="text-2xl font-semibold text-slate-600 dark:text-navy-100">
                Welcome 
              </h2>
              <p className="text-slate-400 dark:text-navy-300">
                Please Sign up to continue
              </p>
            </div>
          </div>

          <Formik
            initialValues={initialValues}
            validationSchema={SignInSchema}
            onSubmit={handleSubmit}
          >
            {(formik) => {
              const { errors, touched, isValid, dirty,values } = formik;

              return (
                <Form>
            <label className="relative flex">
              <Field
                 type="text" name="name"  
                className="form-input peer w-full rounded-lg bg-slate-150 px-3 py-2 pl-9 ring-primary/50 placeholder:text-slate-400 hover:bg-slate-200 focus:ring dark:bg-navy-900/90 dark:ring-accent/50 dark:placeholder:text-navy-300 dark:hover:bg-navy-900 dark:focus:bg-navy-900"
                placeholder="name"
              />
            </label>
            {errors.name && touched.name && (
                      <span className="text-tiny+ text-error">
                        {errors.name}
                      </span>
                    )}

            <label className="relative flex mt-4">
              <Field
                type="email" name="email"  
              
                className="form-input peer w-full rounded-lg bg-slate-150 px-3 py-2 pl-9 ring-primary/50 placeholder:text-slate-400 hover:bg-slate-200 focus:ring dark:bg-navy-900/90 dark:ring-accent/50 dark:placeholder:text-navy-300 dark:hover:bg-navy-900 dark:focus:bg-navy-900"
                placeholder="email"
              />
            </label>
            {errors.email && touched.email && (
                      <span className="text-tiny+ text-error">
                        {errors.email}
                      </span>
                    )}

            <label className="relative flex mt-4">
              <Field
                type="text"
                name="number"
                id="number"
                
              
                className="form-input peer w-full rounded-lg bg-slate-150 px-3 py-2 pl-9 ring-primary/50 placeholder:text-slate-400 hover:bg-slate-200 focus:ring dark:bg-navy-900/90 dark:ring-accent/50 dark:placeholder:text-navy-300 dark:hover:bg-navy-900 dark:focus:bg-navy-900"
                placeholder="Mobile number"
              />
            </label>
            {errors.number && touched.number && (
                      <span className="text-tiny+ text-error">
                        {errors.number}
                      </span>
                    )}

            <label className="relative flex mt-4">
              <Field
              type="password" name="password"  id="password" 
               
                className="form-input peer w-full rounded-lg bg-slate-150 px-3 py-2 pl-9 ring-primary/50 placeholder:text-slate-400 hover:bg-slate-200 focus:ring dark:bg-navy-900/90 dark:ring-accent/50 dark:placeholder:text-navy-300 dark:hover:bg-navy-900 dark:focus:bg-navy-900"
                placeholder="password"
              />
            </label>
            {errors.password && touched.password && (
                      <span className="text-tiny+ text-error">
                        {errors.password}
                      </span>
                    )}

            <label className="relative flex mt-4">
              <Field
               type="password" name="cpassword"   id="cpassword" 
               
                className="form-input peer w-full rounded-lg bg-slate-150 px-3 py-2 pl-9 ring-primary/50 placeholder:text-slate-400 hover:bg-slate-200 focus:ring dark:bg-navy-900/90 dark:ring-accent/50 dark:placeholder:text-navy-300 dark:hover:bg-navy-900 dark:focus:bg-navy-900"
                placeholder="Confirm password"
              />
            </label>
            {errors.cpassword && touched.cpassword && (
                      <span className="text-tiny+ text-error">
                        {errors.cpassword}
                      </span>
                    )}

            <div className="text-center">
              <button type="submit" className="mt-3 w-full btn border border-primary font-medium text-primary hover:bg-primary hover:text-white focus:bg-primary focus:text-white active:bg-primary/90 dark:border-accent dark:text-accent-light dark:hover:bg-accent dark:hover:text-white dark:focus:bg-accent dark:focus:text-white dark:active:bg-accent/90">
                Signup
              </button>
            </div>
            </Form>
            );
          }}
        </Formik>
        <div className='mt-5'>
        <Link to="/login"><button  className="mt-3 w-full btn border border-primary font-medium text-primary hover:bg-primary hover:text-white focus:bg-primary focus:text-white active:bg-primary/90 dark:border-accent dark:text-accent-light dark:hover:bg-accent dark:hover:text-white dark:focus:bg-accent dark:focus:text-white dark:active:bg-accent/90">Login </button></Link> 
        </div>
              
             
        </div>
        <div className="my-5 flex justify-center text-xs text-slate-400 dark:text-navy-300">
          <a href="#">Privacy Notice</a>
          <div className="mx-3 my-1 w-px bg-slate-200 dark:bg-navy-500"></div>
          <a href="#">Term of service</a>
        </div>
      </main>
    </div>
  )
}

export default Signup
