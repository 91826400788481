import {
    Sidebar,
    SubMenu,
    Menu,
    MenuItem,
    useProSidebar,
  } from "react-pro-sidebar";
  import { Outlet, useLocation } from "react-router-dom";
  import { Link } from "react-router-dom";
  import { faHome } from "@fortawesome/free-solid-svg-icons";
  import {
    faUser,
   faShareFromSquare,
  faRightFromBracket,
    faChartPie,
    faPaperclip,
    faBuildingColumns
  } from "@fortawesome/free-solid-svg-icons";
  import {
    faTabletScreenButton,
    faEnvelope,
    faSms,
  } from "@fortawesome/free-solid-svg-icons";
  
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import React from "react";
  import { useState } from "react";
  import sidebarcss from "./sidebar.css";


const InnerSidebar = () => {
  const location = useLocation();
  console.log(location)
    return (
       
              <Sidebar defaultCollapsed className="bg-white h-screen">
              <Menu
                renderExpandIcon={({ open }) => <span>{open ? "-" : "+"}</span>}
              >
               

                <MenuItem
                  className={`font-medium ${
                    window.location.pathname === "/home"
                      ? " text-primary "
                      : ""
                  }`}
                  component={<Link to="/home" />}
                  title="Dashboard"
                  icon={
                    <FontAwesomeIcon
                      className={` ${
                        window.location.pathname === "/home"
                          ? "bg-zinc-300 px-3 py-3 rounded-lg text-primary text-xl"
                          : ""
                      }`}
                      icon={faChartPie}
                    />
                  }
                >
                  {" "}
                  Dashboard
                </MenuItem>

               

              

                <MenuItem
                  className={`font-medium ${
                    window.location.pathname === "/user_profile"
                      ? " text-primary "
                      : ""
                  }`}
                  component={<Link to="user_profile" />}
                  title="user_profile"
                  icon={
                    <FontAwesomeIcon
                      className={` ${
                        window.location.pathname === "/user_profile"
                          ? "bg-zinc-300 px-3 py-3 rounded-lg text-primary text-xl"
                          : ""
                      }`}
                      icon={faUser}
                    />
                  }
                >
                  
                  Profile
                </MenuItem>

                <MenuItem
                  className={`font-medium ${
                    window.location.pathname === "/loan_status"
                      ? " text-primary "
                      : ""
                  }`}
                  component={<Link to="loan_status" />}
                  title="loan status"
                  icon={
                    <FontAwesomeIcon
                      className={` ${
                        window.location.pathname === "/loan_status"
                          ? "bg-zinc-300 px-3 py-3 rounded-lg text-primary text-xl"
                          : ""
                      }`}
                      icon={faBuildingColumns}
                    />
                  }
                >
                  
                  Loan Status
                </MenuItem>

                <MenuItem
                  className={`font-medium ${
                    window.location.pathname === "/student_documents"
                      ? " text-primary "
                      : ""
                  }`}
                  component={<Link to="student_documents" />}
                  title="student documents"
                  icon={
                    <FontAwesomeIcon
                      className={` ${
                        window.location.pathname === "/student_documents"
                          ? "bg-zinc-300 px-3 py-3 rounded-lg text-primary text-xl"
                          : ""
                      }`}
                      icon={faPaperclip}
                    />
                  }
                >
                  
                  Documents
                </MenuItem>


                <MenuItem
                  className={`font-medium ${
                    window.location.pathname === "/referral_code"
                      ? " text-primary "
                      : ""
                  }`}
                  component={<Link to="referral_code" />}
                  title="student documents"
                  icon={
                    <FontAwesomeIcon
                      className={` ${
                        window.location.pathname === "/referral_code"
                          ? "bg-zinc-300 px-3 py-3 rounded-lg text-primary text-xl"
                          : ""
                      }`}
                      icon={faShareFromSquare}
                    />
                  }
                >
                  
                  Refer
                </MenuItem>

                <MenuItem
          className={`font-medium ${
            window.location.pathname === "/logout" ? " text-primary " : ""
          }`}
          component={<Link to="logout" />}
          title="Logout"
          icon={
            <FontAwesomeIcon
              className={` ${
                window.location.pathname === "/logout"
                  ? "bg-zinc-300 px-3 py-3 rounded-lg text-primary text-xl"
                  : ""
              }`}
              icon={faRightFromBracket}
            />
          }
        >
          Log Out
        </MenuItem>

                
              </Menu>
            </Sidebar>
       
    );
};

export default InnerSidebar;