import React, { useState, useEffect } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import {
  faUser,
  faIndianRupee,
  faLandmark,
  faFile,
} from "@fortawesome/free-solid-svg-icons";
import { getSingleFiles, getLoanStatus } from "../Data/apis";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgressbar } from "react-circular-progressbar";
import "./LoanStatus.css"

const LoanStatusDiagram = () => {
  const [loanInfo, setloanInfo] = useState("");

  async function FetchLoanInfo() {
    const response = await getLoanStatus();
    console.log(response, "loan");
    setloanInfo(response.data);
  }

  useEffect(() => {
    FetchLoanInfo();
  }, []);

  return (
    <div>
      <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          iconStyle={{
            backgroundImage:' radial-gradient( circle farthest-corner at 10% 20%,  rgba(14,174,87,1) 0%, rgba(12,116,117,1) 90% )',
            color: "#fff",
          }}
          icon={<FontAwesomeIcon icon={faUser} />}
        >
          <h3 className="vertical-timeline-element-title text-center text-xl">
            Faisal Ali
          </h3>
          <h4 className="vertical-timeline-element-subtitle text-center"></h4>
          <div className="mb-4 flex justify-center">
            <img
              src="https://mdbootstrap.com//img/Photos/Square/1.jpg"
              className=" h-auto rounded-full"
              width="100"
              alt=""
            />
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          iconStyle={
            loanInfo.Document_verification_bool
              ? {
                backgroundImage:' radial-gradient( circle farthest-corner at 10% 20%,  rgba(14,174,87,1) 0%, rgba(12,116,117,1) 90% )',

                  color: "#fff",
                }
              : {
                  backgroundColor: "#fff",

                  color: "black",
                }
          }
          icon={<FontAwesomeIcon icon={faFile} />}
        >
          <h3 className="vertical-timeline-element-title text-2xl font-bold">
            Document Upload Process
          </h3>
          <div className="flex justify-center mt-5">
              <div className=" text-center" style={{ width: "100px" }}>
                <CircularProgressbar value={70} text={"70%"} strokeWidth={5} />
              </div>
            </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          iconStyle={
            loanInfo.Document_verification_bool
              ? {
                backgroundImage:' radial-gradient( circle farthest-corner at 10% 20%,  rgba(14,174,87,1) 0%, rgba(12,116,117,1) 90% )',

                  color: "#fff",
                }
              : {
                  backgroundColor: "#fff",

                  color: "black",
                }
          }
          icon={<FontAwesomeIcon icon={faFile} />}
        >
          <h3 className="vertical-timeline-element-title text-2xl font-bold">
            Document Verification Status
          </h3>
          <h4 className="vertical-timeline-element-subtitle text-xl ">
            {loanInfo.Document_verification_status}
          </h4>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          iconStyle={
            loanInfo.loan_status_bool
              ? {
                backgroundImage:' radial-gradient( circle farthest-corner at 10% 20%,  rgba(14,174,87,1) 0%, rgba(12,116,117,1) 90% )',

                  color: "#fff",
                }
              : {
                  backgroundColor: "#fff",

                  color: "black",
                }
          }
          icon={<FontAwesomeIcon icon={faLandmark} />}
        >
          
          <h3 className="vertical-timeline-element-title text-2xl font-bold">
            Loan Status
          </h3>
          <h4 className="vertical-timeline-element-subtitle text-xl ">
            {loanInfo.loan_status}
          </h4>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          iconStyle={
            loanInfo.loan_final_bool
              ? {
                backgroundImage:' radial-gradient( circle farthest-corner at 10% 20%,  rgba(14,174,87,1) 0%, rgba(12,116,117,1) 90% )',
                  color: "#fff",
                }
              : {
                  backgroundColor: "#fff",

                  color: "black",
                }
          }
          icon={<FontAwesomeIcon icon={faIndianRupee} />}
        >
          <h3 className="vertical-timeline-element-title text-2xl font-bold">
            Bank Loan Application Fee
          </h3>
          <h4 className="vertical-timeline-element-subtitle text-xl ">
            {loanInfo.loan_final_status}
          </h4>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </div>
  );
};

export default LoanStatusDiagram;
