import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import loginImage from '../Images/login.svg';
import thunk from "redux-thunk";
import { getUser } from "../store/authSlice";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { ToastErrorNotifications,ToastSuccessNotifications } from "../Notifications/ToastNotifications";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";


const Login = () => {
  let navigate = useNavigate();

  

  const [loginError, setloginError] = useState("");
  const [email, setemail] = useState("");
  const [verificationEmailShow, setverificationEmailShow] = useState(false);
  const [validationError, setvalidationError] = useState({});

  const initialValues = {
    email: "",
    password: "",
  };

  const SignInSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email is not valid")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const dispatch = useDispatch();

 
  //submitform
  const submitForm = async (values, { resetForm }) => {
   
    const response = await fetch(`http://localhost:5000/api/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: values.email,
        password: values.password,
      }),
    });

    const json = await response.json();

    if (json.success) {
      //redirect
      localStorage.setItem("token", json.authToken);
      localStorage.setItem("activation_form", json.userdata.activation_form);
      dispatch(getUser(json));
      navigate("/home");
      ToastSuccessNotifications("Logged in Successfully")
    } else {
      ToastErrorNotifications("Invalid Credentials")
    }
  };


    
  

  return (
    <div
      id="root"
      className="min-h-100vh flex grow bg-slate-50 dark:bg-navy-900"
    >
      <div className="fixed top-0 hidden p-6 lg:block lg:px-12">
        <a href="#" className="flex items-center space-x-2">
          
       
          <p className="text-xl font-semibold uppercase text-slate-700 dark:text-navy-100">
            
          </p>
        </a>
      </div>

      <div className="hidden w-full place-items-center lg:grid">
        <div className="w-full max-w-lg p-6">
          <img className="w-full" src={loginImage} alt="image" />
        </div>
      </div>

      <main className="flex w-full flex-col items-center bg-white dark:bg-navy-700 lg:max-w-md">
        <div className="flex w-full max-w-sm grow flex-col justify-center p-5">
          <div className="text-center">
            <img
              className="mx-auto h-16 w-16 lg:hidden"
              src="images/app-logo.svg"
              alt="logo"
            />
            <div className="mt-4">
              <h2 className="text-2xl font-semibold text-slate-600 dark:text-navy-100">
                Welcome Back
              </h2>
              <p className="text-slate-400 dark:text-navy-300">
                Please sign in to continue
              </p>
            </div>
          </div>

          <Formik
            initialValues={initialValues}
            validationSchema={SignInSchema}
            onSubmit={submitForm}
          >
            {(formik) => {
              const { errors, touched, isValid, dirty } = formik;

              return (
                <div className="mt-16">
                  <Form>
                    <label className="relative flex">
                      <Field
                        type="text"
                        name="email"
                        id="email"
                        className="form-input peer w-full rounded-lg bg-slate-150 px-3 py-2 pl-9 ring-primary/50 placeholder:text-slate-400 hover:bg-slate-200 focus:ring dark:bg-navy-900/90 dark:ring-accent/50 dark:placeholder:text-navy-300 dark:hover:bg-navy-900 dark:focus:bg-navy-900"
                        placeholder="Email"
                      />
                      <span className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5 transition-colors duration-200"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                            d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                          />
                        </svg>
                      </span>
                    </label>
                    {errors.email && touched.email && (
                      <span className="text-tiny+ text-error">
                        {errors.email}
                      </span>
                    )}
                    <label className="relative mt-4 flex">
                      <Field
                        type="password"
                        name="password"
                        id="password"
                        className="form-input peer  w-full rounded-lg bg-slate-150 px-3 py-2 pl-9 ring-primary/50 placeholder:text-slate-400 hover:bg-slate-200 focus:ring dark:bg-navy-900/90 dark:ring-accent/50 dark:placeholder:text-navy-300 dark:hover:bg-navy-900 dark:focus:bg-navy-900"
                        placeholder="Password"
                      />
                      <span className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5 transition-colors duration-200"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                            d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                          />
                        </svg>
                      </span>
                    </label>
                    {errors.password && touched.password && (
                      <span className="text-tiny+ text-error">
                        {errors.password}
                      </span>
                    )}

                    <ToastContainer />
                    <div className="mt-4 flex items-center justify-between space-x-2">
                      <label className="inline-flex items-center space-x-2">
                        <input
                          className="form-checkbox is-outline  h-5 w-5 rounded border-slate-400/70 bg-slate-100 before:bg-primary checked:border-primary hover:border-primary focus:border-primary dark:border-navy-500 dark:bg-navy-900 dark:before:bg-accent dark:checked:border-accent dark:hover:border-accent dark:focus:border-accent"
                          type="checkbox"
                        />
                        <span className="line-clamp-1">Remember me</span>
                      </label>
                      
                    </div>

                    <button
                      type="submit"
                      // disabled={!(dirty && isValid)}
                      className="mt-3 w-full btn border border-primary font-medium text-primary hover:bg-primary hover:text-white focus:bg-primary focus:text-white active:bg-primary/90 dark:border-accent dark:text-accent-light dark:hover:bg-accent dark:hover:text-white dark:focus:bg-accent dark:focus:text-white dark:active:bg-accent/90"
                    >
                      Sign In
                    </button>

                    
                  </Form>
                  <div className="mt-4 text-center text-xs+">
                    <p className="line-clamp-1">
                      <span>Dont have Account?</span>

                      <Link className="text-primary  " to="/signup">
                     
                        Create account
                      
                      </Link>
                    </p>
                  </div>
                  
                  
                </div>
              );
            }}
          </Formik>
        </div>
        <div className="my-5 flex justify-center text-xs text-slate-400 dark:text-navy-300">
          <a href="#">Privacy Notice</a>
          <div className="mx-3 my-1 w-px bg-slate-200 dark:bg-navy-500"></div>
          <a href="#">Term of service</a>
        </div>
      </main>
    </div>
  );
};

export default Login;
