import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  let navigate = useNavigate();
  console.log("working");

  let link;
  console.log(
    localStorage.getItem("user_admin"),
    localStorage.getItem("user_dsa")
  );


  if (localStorage.getItem("user_admin") == 1) {
    
    link = "/admin";
  } else if (localStorage.getItem("user_dsa") == 1) {
   
    link = "/dsa";
  } else {
   
    link = "/login";
  }
  console.log(link);
  

  useEffect(() => {
    navigate(link);
    localStorage.removeItem("user_admin");
    localStorage.removeItem("user_dsa");
    localStorage.removeItem("token");
  }, []);

  return <div></div>;
};

export default Logout;
