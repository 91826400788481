import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Navbar from "./Component/Navbar";
const WebsiteHome = () => {
  return (
    <div>
      {/* Mirrored from templates.envytheme.com/finix/default/index-2.html by HTTrack Website Copier/3.x [XR&CO'2014], Sun, 26 Feb 2023 16:39:57 GMT */}
      {/* Required meta tags */}
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        {/* Bootstrap CSS */}
        <link rel="stylesheet" href="assets/css/bootstrap.min.css" />
        {/* Animate CSS */}
        <link rel="stylesheet" href="assets/css/animate.min.css" />
        {/* Meanmenu CSS */}
        <link rel="stylesheet" href="assets/css/meanmenu.css" />
        {/* Boxicons CSS */}
        <link rel="stylesheet" href="assets/css/boxicons.min.css" />
        {/* Flaticon CSS */}
        <link rel="stylesheet" href="assets/css/flaticon.css" />
        {/* Carousel CSS */}
        <link rel="stylesheet" href="assets/css/owl.carousel.min.css" />
        {/* Carousel Default CSS */}
        <link rel="stylesheet" href="assets/css/owl.theme.default.min.css" />
        {/* Magnific Popup CSS */}
        <link rel="stylesheet" href="assets/css/magnific-popup.min.css" />
        {/* Nice Select CSS */}
        <link rel="stylesheet" href="assets/css/nice-select.min.css" />
        {/* Odometer CSS */}
        <link rel="stylesheet" href="assets/css/odometer.min.css" />
        {/* Style CSS */}
        <link rel="stylesheet" href="assets/css/style.css" />
        {/* Dark CSS */}
        <link rel="stylesheet" href="assets/css/dark.css" />
        {/* Responsive CSS */}
        <link rel="stylesheet" href="assets/css/responsive.css" />
        <title>Finix - Loans &amp; Funding HTML Template</title>
        <link rel="icon" type="image/png" href="assets/img/favicon.png" />
      </Helmet>
      {/* Start Navbar Area */}

      <Navbar />

      {/* End Navbar Area */}
      {/* Start Main Banner Area */}
      <div className="main-banner-area bg-two">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container-fluid">
              <div className="main-banner-content">
                <span>We are here for</span>
                <h1>We believe in those made to do more</h1>
                <p>
                  Loans are advantageous as a relatively inexpensive way of
                  borrowing money. Start or grow your own business
                </p>
                <div className="banner-btn">
                  <a href="apply-now.html" className="default-btn">
                    View more
                    <span />
                  </a>
                </div>
              </div>
              <div className="banner-social-buttons">
                <ul>
                  <li>
                    <span>Follow us</span>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      <i className="flaticon-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      <i className="flaticon-instagram" />
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      <i className="flaticon-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      <i className="flaticon-linkedin" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Main Banner Area */}
      {/* Start Partner Area */}
      <div className="partner-area bg-color ptb-100">
        <div className="container">
          <div className="partner-slider owl-carousel owl-theme">
            <div className="partner-item">
              <img src="assets/img/partner/image1.png" alt="image" />
            </div>
            <div className="partner-item">
              <img src="assets/img/partner/image2.png" alt="image" />
            </div>
            <div className="partner-item">
              <img src="assets/img/partner/image3.png" alt="image" />
            </div>
            <div className="partner-item">
              <img src="assets/img/partner/image4.png" alt="image" />
            </div>
            <div className="partner-item">
              <img src="assets/img/partner/image5.png" alt="image" />
            </div>
          </div>
        </div>
      </div>
      {/* End Partner Area */}
      {/* Start About Area Area */}
      <section className="about-area ptb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-wrap">
                <img src="assets/img/about/image3.png" alt="image" />
                <div className="about-shape">
                  <div className="text">
                    <h3>25</h3>
                    <span>Years Of Experience</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-content">
                <span>About company</span>
                <h3>
                  We have been working very efficiently with loan and funding
                  for 25 years.
                </h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Quis ipsum suspendisse ultrices gravida.
                </p>
                <strong>In business, we focus on 3 things.</strong>
                <ul className="about-list">
                  <li>
                    <i className="flaticon-check" />
                    Useful info
                  </li>
                  <li>
                    <i className="flaticon-check" />
                    Reliability
                  </li>
                  <li>
                    <i className="flaticon-check" />
                    Innovation
                  </li>
                </ul>
                <div className="about-btn">
                  <a href="apply-now.html" className="default-btn">
                    View more
                    <span />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End About Area Area */}
      {/* Start Choose Area */}
      <section className="choose-area pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="choose-title">
                <span>Transparent process</span>
                <h2>Why people choose us</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
              </div>
              <div className="choose-image">
                <img src="assets/img/choose.png" alt="image" />
                <a
                  href="https://www.youtube.com/watch?v=qaHWDmFtBl0"
                  className="video-btn popup-youtube"
                >
                  <i className="flaticon-play-button" />
                </a>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="process-item bg-top1">
                    <div className="icon">
                      <i className="flaticon-guarantee" />
                    </div>
                    <h3>Guarantee</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="process-item bg-top2">
                    <div className="icon">
                      <i className="flaticon-speed" />
                    </div>
                    <h3>Speed</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="process-item bg-top3">
                    <div className="icon">
                      <i className="flaticon-reliability" />
                    </div>
                    <h3>Reliability</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="process-item bg-top4">
                    <div className="icon">
                      <i className="flaticon-user-experience" />
                    </div>
                    <h3>Experience</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Choose Area */}
      {/* Start Deserve Area */}
      <section className="deserve-area ptb-100">
        <div className="container">
          <div className="section-title">
            <span>Funding circles</span>
            <h2>You deserve a better business loan</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
            </p>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="deserve-item">
                <h3>
                  We usually follow 4 steps to get a better business loans.
                </h3>
                <div className="deserve-content">
                  <span>1</span>
                  <h4>Apply in 10 minutes</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                </div>
                <div className="deserve-content">
                  <span>2</span>
                  <h4>Hear from us in 1 hour</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                </div>
                <div className="deserve-content">
                  <span>3</span>
                  <h4>A decision in 24 hours</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                </div>
                <div className="deserve-content">
                  <span>4</span>
                  <h4>Your loan is funded</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                </div>
                <div className="deserve-btn">
                  <a href="apply-now.html" className="default-btn">
                    Apply now
                    <span />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="rate-form bg-fdebeb">
                <div className="rate-content">
                  <span>Calculate your rate</span>
                  <h3>How much do you need?</h3>
                </div>
                <div className="form">
                  <div className="form-group">
                    <label>Type The Money</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="$1000"
                    />
                  </div>
                  <div className="form-group">
                    <label>Select The Month/Years</label>
                    <div className="select-box">
                      <select className="form-control">
                        <option value={5}>1 month</option>
                        <option value={1}>2 month</option>
                        <option value={2}>3 month</option>
                        <option value={0}>4 month</option>
                        <option value={3}>6 month</option>
                        <option value={3}>1 Year</option>
                        <option value={0}>2 Years</option>
                        <option value={3}>3 Years</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Borrowing</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="$10000"
                    />
                  </div>
                  <div className="form-group">
                    <label>Term of use</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="6 month"
                    />
                  </div>
                  <div className="form-group">
                    <label>The total you will pay</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="$11200"
                    />
                  </div>
                  <div className="rate-btn">
                    <button type="submit" className="default-btn">
                      Apply for this loan
                      <span />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Deserve Area */}
      {/* Start Solution Area */}
      <section className="solution-area">
        <div className="container">
          <div className="solution-item">
            <div className="row align-items-center">
              <div className="col-lg-7">
                <div className="content">
                  <h3>
                    We are here to help your business Call to discuss your
                    Solution
                  </h3>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="solution-info">
                  <i className="flaticon-telephone" />
                  <h3>
                    <a href="tel:15143125678">+1 (514) 312-5678</a>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Solution Area */}
      {/* Start Company Area */}
      <section className="company-area bg-color ptb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="company-content">
                <h3>Our company values</h3>
                <div className="company-text">
                  <div className="icon">
                    <i className="flaticon-idea" />
                  </div>
                  <h4>Innovative</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                </div>
                <div className="company-text">
                  <div className="icon">
                    <i className="flaticon-talent" />
                  </div>
                  <h4>Talent</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                </div>
                <div className="company-text">
                  <div className="icon">
                    <i className="flaticon-key" />
                  </div>
                  <h4>Enabling</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                </div>
                <div className="company-text">
                  <div className="icon">
                    <i className="flaticon-responsibility" />
                  </div>
                  <h4>Commercially responsible</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="company-wrap">
                <img src="assets/img/company2.png" alt="image" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Company Area */}
      {/* Start Best Services Area */}
      <section className="best-services-area ptb-100">
        <div className="container">
          <div className="section-title">
            <span>Knowledge of the market</span>
            <h2>Only the best professional services</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
            </p>
          </div>
          <div className="best-services-slider">
            <div id="thumbs" className="owl-carousel owl-theme">
              <div className="tabs-item">
                <a href="#">
                  <i className="flaticon-agriculture" />
                  <span>Agricultural loan</span>
                </a>
              </div>
              <div className="tabs-item">
                <a href="#">
                  <i className="flaticon-personal" />
                  <span>Personal loan</span>
                </a>
              </div>
              <div className="tabs-item">
                <a href="#">
                  <i className="flaticon-loan-1" />
                  <span>Business loan</span>
                </a>
              </div>
              <div className="tabs-item">
                <a href="#">
                  <i className="flaticon-scholarship" />
                  <span>Education loan</span>
                </a>
              </div>
              <div className="tabs-item">
                <a href="#">
                  <i className="flaticon-loan-2" />
                  <span>House loan</span>
                </a>
              </div>
              <div className="tabs-item">
                <a href="#">
                  <i className="flaticon-loan-3" />
                  <span>Payday loan</span>
                </a>
              </div>
              <div className="tabs-item">
                <a href="#">
                  <i className="flaticon-agriculture" />
                  <span>Agricultural loan</span>
                </a>
              </div>
            </div>
            <div id="best-services" className="owl-carousel owl-theme">
              <div className="services-tabs-item">
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div className="services-tab-image">
                      <img src="assets/img/services-tab.png" alt="image" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="services-tab-content">
                      <h3>Agricultural loan</h3>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Quis ipsum suspendisse ultrices gravida.
                        Risus commodo viverra maecenas accumsan lacus vel
                        facilisis.
                      </p>
                    </div>
                    <ul className="list">
                      <h3>Types of business loan</h3>
                      <li>Secured loans</li>
                      <li>Unsecured loans</li>
                      <li>Revolving credit facilities</li>
                      <li>Business cash advances</li>
                    </ul>
                    <div className="services-tab-content">
                      <h3>Eligibility and criteria for Agricultural loan</h3>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua.
                      </p>
                    </div>
                    <div className="services-tab-content left">
                      <h3>Agricultural loan guide</h3>
                      <p>
                        Quis ipsum suspendisse ultrices gravida. Risus commodo
                        viverra maecenas accumsan lacus vel facilisis.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="services-tabs-item">
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div className="services-tab-image">
                      <img src="assets/img/services-tab.png" alt="image" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="services-tab-content">
                      <h3>Personal loan</h3>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Quis ipsum suspendisse ultrices gravida.
                        Risus commodo viverra maecenas accumsan lacus vel
                        facilisis.
                      </p>
                    </div>
                    <ul className="list">
                      <h3>Types of business loan</h3>
                      <li>Secured loans</li>
                      <li>Unsecured loans</li>
                      <li>Revolving credit facilities</li>
                      <li>Business cash advances</li>
                    </ul>
                    <div className="services-tab-content">
                      <h3>Eligibility and criteria for Personal loan</h3>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua.
                      </p>
                    </div>
                    <div className="services-tab-content left">
                      <h3>Personal loan guide</h3>
                      <p>
                        Quis ipsum suspendisse ultrices gravida. Risus commodo
                        viverra maecenas accumsan lacus vel facilisis.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="services-tabs-item">
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div className="services-tab-image">
                      <img src="assets/img/services-tab.png" alt="image" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="services-tab-content">
                      <h3>Business loans</h3>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Quis ipsum suspendisse ultrices gravida.
                        Risus commodo viverra maecenas accumsan lacus vel
                        facilisis.
                      </p>
                    </div>
                    <ul className="list">
                      <h3>Types of business loan</h3>
                      <li>Secured loans</li>
                      <li>Unsecured loans</li>
                      <li>Revolving credit facilities</li>
                      <li>Business cash advances</li>
                    </ul>
                    <div className="services-tab-content">
                      <h3>Eligibility and criteria for business loans</h3>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua.
                      </p>
                    </div>
                    <div className="services-tab-content left">
                      <h3>Business loans guide</h3>
                      <p>
                        Quis ipsum suspendisse ultrices gravida. Risus commodo
                        viverra maecenas accumsan lacus vel facilisis.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="services-tabs-item">
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div className="services-tab-image">
                      <img src="assets/img/services-tab.png" alt="image" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="services-tab-content">
                      <h3>Education loan</h3>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Quis ipsum suspendisse ultrices gravida.
                        Risus commodo viverra maecenas accumsan lacus vel
                        facilisis.
                      </p>
                    </div>
                    <ul className="list">
                      <h3>Types of business loan</h3>
                      <li>Secured loans</li>
                      <li>Unsecured loans</li>
                      <li>Revolving credit facilities</li>
                      <li>Business cash advances</li>
                    </ul>
                    <div className="services-tab-content">
                      <h3>Eligibility and criteria for Education loan</h3>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua.
                      </p>
                    </div>
                    <div className="services-tab-content left">
                      <h3>Education loan guide</h3>
                      <p>
                        Quis ipsum suspendisse ultrices gravida. Risus commodo
                        viverra maecenas accumsan lacus vel facilisis.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="services-tabs-item">
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div className="services-tab-image">
                      <img src="assets/img/services-tab.png" alt="image" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="services-tab-content">
                      <h3>House loan</h3>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Quis ipsum suspendisse ultrices gravida.
                        Risus commodo viverra maecenas accumsan lacus vel
                        facilisis.
                      </p>
                    </div>
                    <ul className="list">
                      <h3>Types of business loan</h3>
                      <li>Secured loans</li>
                      <li>Unsecured loans</li>
                      <li>Revolving credit facilities</li>
                      <li>Business cash advances</li>
                    </ul>
                    <div className="services-tab-content">
                      <h3>Eligibility and criteria for House loan</h3>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua.
                      </p>
                    </div>
                    <div className="services-tab-content left">
                      <h3>House loan guide</h3>
                      <p>
                        Quis ipsum suspendisse ultrices gravida. Risus commodo
                        viverra maecenas accumsan lacus vel facilisis.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="services-tabs-item">
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div className="services-tab-image">
                      <img src="assets/img/services-tab.png" alt="image" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="services-tab-content">
                      <h3>Payday loan</h3>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Quis ipsum suspendisse ultrices gravida.
                        Risus commodo viverra maecenas accumsan lacus vel
                        facilisis.
                      </p>
                    </div>
                    <ul className="list">
                      <h3>Types of business loan</h3>
                      <li>Secured loans</li>
                      <li>Unsecured loans</li>
                      <li>Revolving credit facilities</li>
                      <li>Business cash advances</li>
                    </ul>
                    <div className="services-tab-content">
                      <h3>Eligibility and criteria for Payday loan</h3>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua.
                      </p>
                    </div>
                    <div className="services-tab-content left">
                      <h3>Payday loan guide</h3>
                      <p>
                        Quis ipsum suspendisse ultrices gravida. Risus commodo
                        viverra maecenas accumsan lacus vel facilisis.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="services-tabs-item">
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div className="services-tab-image">
                      <img src="assets/img/services-tab.png" alt="image" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="services-tab-content">
                      <h3>Agricultural loan</h3>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Quis ipsum suspendisse ultrices gravida.
                        Risus commodo viverra maecenas accumsan lacus vel
                        facilisis.
                      </p>
                    </div>
                    <ul className="list">
                      <h3>Types of business loan</h3>
                      <li>Secured loans</li>
                      <li>Unsecured loans</li>
                      <li>Revolving credit facilities</li>
                      <li>Business cash advances</li>
                    </ul>
                    <div className="services-tab-content">
                      <h3>Eligibility and criteria for Agricultural loan</h3>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua.
                      </p>
                    </div>
                    <div className="services-tab-content left">
                      <h3>Agricultural loan guide</h3>
                      <p>
                        Quis ipsum suspendisse ultrices gravida. Risus commodo
                        viverra maecenas accumsan lacus vel facilisis.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Best Services Area */}
      {/* Start Clients Area */}
      <section className="clients-area pt-100 pb-70">
        <div className="container">
          <div className="section-title">
            <span>Clients words</span>
            <h2>What our clients say</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
            </p>
          </div>
          <div className="clients-slider owl-carousel owl-theme">
            <div className="clients-item">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="clients-info-text">
                    <p>
                      “Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Quis ipsum suspendisse ultrices gravida. Risus
                      commodo viverra maecenas accumsan lacus vel facilisis.”
                    </p>
                    <h3>Debra C. Cowen</h3>
                    <span>Founder</span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="clients-image">
                    <img src="assets/img/clients/man.png" alt="image" />
                    <div className="icon-1">
                      <i className="flaticon-right-quote" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="clients-item">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="clients-info-text">
                    <p>
                      “Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Quis ipsum suspendisse ultrices gravida. Risus
                      commodo viverra maecenas accumsan lacus vel facilisis.”
                    </p>
                    <h3>Debra C. Cowen</h3>
                    <span>Founder</span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="clients-image">
                    <img src="assets/img/clients/man.png" alt="image" />
                    <div className="icon-1">
                      <i className="flaticon-right-quote" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="clients-item">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="clients-info-text">
                    <p>
                      “Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Quis ipsum suspendisse ultrices gravida. Risus
                      commodo viverra maecenas accumsan lacus vel facilisis.”
                    </p>
                    <h3>Debra C. Cowen</h3>
                    <span>Founder</span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="clients-image">
                    <img src="assets/img/clients/man.png" alt="image" />
                    <div className="icon-1">
                      <i className="flaticon-right-quote" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Clients Area */}
      {/* Start Team Area */}
      <section className="team-area pt-100 pb-70">
        <div className="container">
          <div className="section-title">
            <span>Expert People</span>
            <h2>Global leadership team</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
          </div>
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="team-item">
                <div className="image">
                  <img src="assets/img/team/image1.jpg" alt="image" />
                  <ul className="social">
                    <li>
                      <a href="#" target="_blank">
                        <i className="bx bxl-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="bx bxl-linkedin" />
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="bx bxl-facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="bx bxl-instagram" />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="content">
                  <h3>Jerome Le Luel</h3>
                  <span>Managing Director</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="team-item">
                <div className="image">
                  <img src="assets/img/team/image2.jpg" alt="image" />
                  <ul className="social">
                    <li>
                      <a href="#" target="_blank">
                        <i className="bx bxl-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="bx bxl-linkedin" />
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="bx bxl-facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="bx bxl-instagram" />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="content">
                  <h3>Andrew Learoyd</h3>
                  <span>Chief Executive Officer</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="team-item">
                <div className="image">
                  <img src="assets/img/team/image3.jpg" alt="image" />
                  <ul className="social">
                    <li>
                      <a href="#" target="_blank">
                        <i className="bx bxl-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="bx bxl-linkedin" />
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="bx bxl-facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="bx bxl-instagram" />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="content">
                  <h3>Harry Nelis</h3>
                  <span>Technology Officer</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="team-item">
                <div className="image">
                  <img src="assets/img/team/image4.jpg" alt="image" />
                  <ul className="social">
                    <li>
                      <a href="#" target="_blank">
                        <i className="bx bxl-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="bx bxl-linkedin" />
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="bx bxl-facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="bx bxl-instagram" />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="content">
                  <h3>Lucy Vernall</h3>
                  <span>Chief Financial Officer</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Team Area */}
      {/* Start Blog Area */}
      <section className="blog-area pt-100 pb-70">
        <div className="container">
          <div className="section-title">
            <span>Recent news</span>
            <h2>Success story posts</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
            </p>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="blog-item">
                <div className="image">
                  <a href="news-details.html">
                    <img src="assets/img/blog/image1.jpg" alt="image" />
                  </a>
                </div>
                <div className="content">
                  <span>July 05, 2022</span>
                  <h3>
                    <a href="news-details.html">
                      Financing loans available to small businesses
                    </a>
                  </h3>
                  <a href="news-details.html" className="blog-btn">
                    Read More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="blog-item">
                <div className="image">
                  <a href="news-details.html">
                    <img src="assets/img/blog/image2.jpg" alt="image" />
                  </a>
                </div>
                <div className="content">
                  <span>July 04, 2022</span>
                  <h3>
                    <a href="news-details.html">
                      5 Ways you can prepare your business for success
                    </a>
                  </h3>
                  <a href="news-details.html" className="blog-btn">
                    Read More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
              <div className="blog-item">
                <div className="image">
                  <a href="news-details.html">
                    <img src="assets/img/blog/image3.jpg" alt="image" />
                  </a>
                </div>
                <div className="content">
                  <span>July 03, 2022</span>
                  <h3>
                    <a href="news-details.html">
                      Tips for saving money and better guide for investment
                    </a>
                  </h3>
                  <a href="news-details.html" className="blog-btn">
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Blog Area */}
      {/* Start Footer Area */}
      <section className="footer-area pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="single-footer-widget">
                <div className="logo">
                  <a href="#">
                    <img src="assets/img/logo2.png" alt="image" />
                  </a>
                </div>
                <p>
                  Lorem ipsum dolor sit amet, consec tetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
                <ul className="social">
                  <li>
                    <b>Follow us:</b>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      <i className="flaticon-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      <i className="flaticon-instagram" />
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      <i className="flaticon-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      <i className="flaticon-linkedin" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-footer-widget">
                <h3>Quick Links</h3>
                <ul className="quick-links">
                  <li>
                    <a href="about.html">About</a>
                  </li>
                  <li>
                    <a href="#">Our Performance</a>
                  </li>
                  <li>
                    <a href="faq.html">Help (FAQ)</a>
                  </li>
                  <li>
                    <a href="news.html">Blog</a>
                  </li>
                  <li>
                    <a href="contact.html">Contact</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-footer-widget">
                <h3>Other Resources</h3>
                <ul className="quick-links">
                  <li>
                    <a href="#">Support</a>
                  </li>
                  <li>
                    <a href="privacy-policy.html">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="terms-condition.html">Terms of Service</a>
                  </li>
                  <li>
                    <a href="#">Business Loans</a>
                  </li>
                  <li>
                    <a href="#">Loan Services</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-footer-widget">
                <h3>Contact Us</h3>
                <div className="info-contact">
                  <i className="flaticon-pin" />
                  <span>6890 Blvd, The Bronx, NY 1058 New York, USA</span>
                </div>
                <div className="info-contact">
                  <i className="flaticon-mail" />
                  <span>
                    <a href="https://templates.envytheme.com/cdn-cgi/l/email-protection#aec6cbc2c2c1eec8c7c0c7d680cdc1c3">
                      <span
                        className="__cf_email__"
                        data-cfemail="a5cdc0c9c9cae5c3cccbccdd8bc6cac8"
                      >
                        [email&nbsp;protected]
                      </span>
                    </a>
                  </span>
                  <span>
                    <a href="#">skype: example</a>
                  </span>
                </div>
                <div className="info-contact">
                  <i className="flaticon-telephone" />
                  <span>
                    <a href="tel:1514312-6688">+1 (514) 312-5678</a>
                  </span>
                  <span>
                    <a href="tel:1514312-6688">+1 (514) 312-6688</a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Footer Area */}
      {/* Start Copy Right Area */}
      <div className="copy-right-area">
        <div className="container">
          <div className="copy-right-content">
            <p>
              Copyright @ Finix. Designed By
              <a href="https://envytheme.com/" target="_blank">
                EnvyTheme
              </a>
            </p>
          </div>
        </div>
      </div>
      {/* End Copy Right Area */}
      {/* Start Go Top Area */}
      <div className="go-top">
        <i className="bx bx-chevron-up" />
      </div>
      {/* End Go Top Area */}
      {/* Dark version */}
      <div className="dark-version">
        <label id="switch" className="switch">
          <input type="checkbox" id="slider" />
          <span className="slider round" />
        </label>
      </div>
      {/* Dark version */}
      {/* Jquery Slim JS */}
      {/* Popper JS */}
      {/* Bootstrap JS */}
      {/* Meanmenu JS */}
      {/* Carousel JS */}
      {/* Nice Select JS */}
      {/* Magnific Popup JS */}
      {/* Odometer JS */}
      {/* Appear JS */}
      {/* Form Ajaxchimp JS */}
      {/* Form Validator JS */}
      {/* Contact JS */}
      {/* Wow JS */}
      {/* Custom JS */}
      {/* Mirrored from templates.envytheme.com/finix/default/index-2.html by HTTrack Website Copier/3.x [XR&CO'2014], Sun, 26 Feb 2023 16:40:15 GMT */}
    </div>
  );
};

export default WebsiteHome;
