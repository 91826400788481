import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ToastErrorNotifications } from "../../Notifications/ToastNotifications";
import { useNavigate } from "react-router-dom";
import { fetchUserDetails } from "../../Data/apis";
import UserProfileEdit from "../../Components/UserProfileEdit";
import { getUser } from "../../store/userDetailSlice";
import { useSelector, useDispatch } from "react-redux";

const UserProfile = () => {
  const [userDetails, setUserDetails] = useState("");
  const dispatch = useDispatch();

  async function getUserDetails() {
    const response = await fetchUserDetails();
    console.log(response);
    if (response.success == true) {
    }
    setUserDetails(response.data);
    dispatch(getUser(response.data));
  }

  useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <div>
      <div className="flex justify-center">
        <div className="card  my-5 mx-8" style={{ width: "1200px" }}>
          <span className="text-xl text-center my-5 font-bold">Personal Details</span>

          <div className="folio">
            <div className="folio-item">
              <label>Full Name : </label>
              <span>{userDetails.fullname}</span>
            </div>
            <div className="folio-item">
              <label>D.O.B. : </label>
              <span>{userDetails.dob}</span>
            </div>
            <div className="folio-item">
              <label>Email : </label>
              <span>{userDetails.email}</span>
            </div>

            <div className="folio-item">
              <label>Mobile Number : </label>
              <span>{userDetails.number}</span>
            </div>

            <div className="folio-item">
              <label>Gender : </label>
              <span>{userDetails.gender}</span>
            </div>

            <div className="folio-item">
              <label>Occupation : </label>
              <span>{userDetails.occupation}</span>
            </div>
          </div>
          <span className="text-xl text-center font-bold my-5">Academic Details</span>

          <div className="folio">
            <div className="folio-item">
              <label>Course : </label>
              <span>{userDetails.course}</span>
            </div>
            <div className="folio-item">
              <label>Joining Year : </label>
              <span>{userDetails.joiningyear}</span>
            </div>
            <div className="folio-item">
              <label>10th Grade : </label>
              <span>{userDetails.metric}</span>
            </div>

            <div className="folio-item">
              <label>12th Grade : </label>
              <span>{userDetails.highersecondary}</span>
            </div>

            <div className="folio-item">
              <label>Graduated Course : </label>
              <span>{userDetails.passedcourse}</span>
            </div>

            <div className="folio-item">
              <label>Graduated With Grade : </label>
              <span>{userDetails.passinggrade}</span>
            </div>
          </div>
          <span className="text-xl text-center font-bold my-5">Address Details</span>

          <div className="folio">
            <div className="folio-item">
              <label>Address : </label>
              <span>{userDetails.address}</span>
            </div>
            <div className="folio-item">
              <label>Street Name : </label>
              <span>{userDetails.address2}</span>
            </div>
            <div className="folio-item">
              <label>State : </label>
              <span>{userDetails.state}</span>
            </div>

            <div className="folio-item">
              <label>District : </label>
              <span>{userDetails.district}</span>
            </div>

            <div className="folio-item">
              <label>City : </label>
              <span>{userDetails.city}</span>
            </div>

            <div className="folio-item">
              <label>Zip Code : </label>
              <span>{userDetails.zipcode}</span>
            </div>
          </div>
          <span className="text-xl text-center font-bold my-5">Family Details</span>

          <div className="folio">
            <div className="folio-item">
              <label>Father Name : </label>
              <span>{userDetails.fathersname}</span>
            </div>
            <div className="folio-item">
              <label>Mother Name : </label>
              <span>{userDetails.mothersname}</span>
            </div>
            <div className="folio-item">
              <label>Father's Occupation : </label>
              <span>{userDetails.fatheroccupation}</span>
            </div>

            <div className="folio-item">
              <label>Mother's Occupation : </label>
              <span>{userDetails.motheroccupation}</span>
            </div>

            <div className="folio-item">
              <label>Family Annual Income : </label>
              <span>{userDetails.familyincome}</span>
            </div>

            <div className="folio-item">
              <label>Parent Contact Number : </label>
              <span>{userDetails.parentnumber}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center mt-5">
        <UserProfileEdit fetchUserDetailsFunction={getUserDetails} />
      </div>
    </div>
  );
};

export default UserProfile;
